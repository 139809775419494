import styles from '.././styles/Carousel.module.css'
import CarouselCard from './CarouselCard'
import { data } from './Data'
import React, { useState } from 'react'

const Carousel = () => {
  const next = process.env.PUBLIC_URL + '/images/landingPage/next.png'
  const prev = process.env.PUBLIC_URL + '/images/landingPage/prev.png'
  const [activeIndex, setActiveIndex] = useState(0) // Start with index 1 to display the first card in the middle
  const cardsLength = data.length
  console.log('cards length is: ', cardsLength)

  const [slideDirection, setSlideDirection] = useState('')

  const goToNextCard = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % cardsLength)
    setSlideDirection('right')
  }

  const goToPrevCard = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + cardsLength) % cardsLength)
    setSlideDirection('left')
  }

  // Calculate previous and next indices
  const prevIndex = activeIndex === 0 ? cardsLength - 1 : activeIndex - 1
  const nextIndex = activeIndex === cardsLength - 1 ? 0 : activeIndex + 1

  console.log('The active indix is: ', activeIndex)
  console.log('The prev indix is: ', prevIndex)
  console.log('The next indix is: ', nextIndex)

  console.log('The prev data is: ', data[prevIndex])
  console.log('The active data is: ', data[activeIndex])

  return (
    <>
      <div className={styles.carouselContainer}>
        <button className={styles.button} onClick={goToPrevCard}>
          <img className={styles.img} alt="prev" src={prev} />
        </button>
        <div className={styles.cardsContainer}>
          {typeof prevIndex === 'number' && (
            <CarouselCard
              item={data[prevIndex]}
              location={'side'}
              animationDirection={slideDirection}
            />
          )}
          {typeof activeIndex === 'number' && (
            <CarouselCard
              item={data[activeIndex]}
              location={'active'}
              animationDirection={slideDirection}
            />
          )}
          {typeof nextIndex === 'number' && (
            <CarouselCard
              item={data[nextIndex]}
              location={'side'}
              animationDirection={slideDirection}
            />
          )}
        </div>
        <button onClick={goToNextCard} className={styles.button}>
          <img className={styles.img} alt="next" src={next} />
        </button>
      </div>
      <div className={styles.dotsContainer}>
        {Array.from({ length: cardsLength }).map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${
              index === activeIndex ? styles.activeDot : ''
            }`}
          />
        ))}
      </div>
    </>
  )
}

export default Carousel
