import styles from '../../styles/UserTokenUsage.module.css'
import { useState, useEffect, useRef } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Container from '../Container'

const UsersTokenUsage = ({ data, message }) => {
  const [expandedUserId, setExpandedUserId] = useState(null)
  const expandedRef = useRef(null) // Create a ref for the expanded element

  const toggleExpand = (userId) => {
    setExpandedUserId(expandedUserId === userId ? null : userId)
  }

  useEffect(() => {
    if (expandedUserId !== null && expandedRef.current) {
      expandedRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [expandedUserId])

  const isDataAvailable = data && data.length > 0

  return (
    <Container
      text="Users Chatbots Token Usage"
      description="Explore the user-centric overview in this section, where you can unfold the token metrics for every active chatbot associated with a user. Click on a user to reveal a detailed list of their chatbots, each accompanied by a comprehensive token summary. This interactive breakdown provides insights into total tokens accrued, tokens that have been utilized for triggering chatbot actions ('In'), and those generated by chatbot responses ('Out'), offering a clear view of activity and engagement levels for each user-chatbot pairing."
    >
      <div className={styles.dataContainer}>
        {isDataAvailable ? (
          <ol className={styles.list}>
            {data.map((user, index) => (
              <li
                key={user.user_id}
                className={styles.details}
                onClick={() => toggleExpand(user.user_id)}
              >
                <div className={styles.liContainer}>
                  <div>
                    {user.name} {user.surname}
                  </div>
                  <ExpandMoreIcon className={styles.expandButton} />
                </div>

                {expandedUserId === user.user_id && (
                  <div ref={expandedRef} className={styles.chatbotsContainer}>
                    {user.chatbots.some((chatbot) => chatbot.chatbot_id) ? (
                      user.chatbots.map(
                        (chatbot) =>
                          chatbot.chatbot_id && (
                            <div
                              key={chatbot.chatbot_id}
                              className={styles.chatbot}
                            >
                              <img
                                className={styles.chatbotAvatar}
                                src={`${process.env.PUBLIC_URL}/images/avatars/${chatbot.avatar}.png`}
                                alt={chatbot.chatbot_name}
                              />
                              <div
                                style={{
                                  'text-align': 'center',
                                  'padding-bottom': '0.6em',
                                }}
                              >
                                {chatbot.chatbot_name}
                              </div>
                              <div style={{ color: '#02b2af' }}>
                                Total Tokens: {chatbot.total_tokens}
                              </div>
                              <div style={{ color: '#2e96ff' }}>
                                In Tokens: {chatbot.in_tokens}
                              </div>
                              <div style={{ color: '#b800d8' }}>
                                Out Tokens: {chatbot.out_tokens}
                              </div>
                            </div>
                          ),
                      )
                    ) : (
                      <p className={styles.infoText}>No chatbots available.</p>
                    )}
                  </div>
                )}
              </li>
            ))}
          </ol>
        ) : (
          <p className={styles.infoText}>{message}</p>
        )}
      </div>
    </Container>
  )
}

export default UsersTokenUsage
