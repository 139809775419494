import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { Fragment, useEffect, useState, useRef, useCallback } from 'react'
import styles from '../../styles/ChatWindow.module.css'
import {
  getChatMessages,
  updateChatMessages,
  createNewChat,
  sendPromptToChatbot,
  generateTitle,
  getImage,
} from '../../services/API/ChatServices'
import ErrorCard from '../ErrorCard'
//import { getChatbotDataFromSession } from '../../utils/SessionStorageUtil'
import { encodingForModel } from 'js-tiktoken'
import SendButton from '../SendButton'
import ReactMarkdown from 'react-markdown'
import DOMPurify from 'dompurify'
import socketIOClient from 'socket.io-client'
import DropZoneInput from './DropZoneInput'
import Compressor from 'compressorjs'
import ImageContainer from './ImageContainer'
import VoiceRecorder from './VoiceRecorder'
import VoiceRecorder2 from './VoiceRecorder2'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import VideoSwitch from './VideoSwitch'
import LoadingSpinner from './LoadingSpinner'
import {
  fetchIDLEvideo,
  fetchDIDtalk,
} from './../../services/API/VideoServices'

const ChatWindow = () => {
  const imagePathUser =
    process.env.PUBLIC_URL + '/images/avatars/avatarUser2.png'
  // useParams lets us grab info from the webpage's address, like IDs.
  const { chatBotId, conversationId } = useParams()

  //Retrieve from the outlet context
  const { setChatMessagesSaved, chatMessagesSaved, chatbotData2 } =
    useOutletContext()

  const [chatbotInSession, setChatbotInSession] = useState(chatbotData2)

  useEffect(() => {
    setChatbotInSession(chatbotData2)
  }, [chatbotData2])

  const imagePath = chatbotInSession.avatar
    ? `${process.env.PUBLIC_URL}/images/avatars/${chatbotInSession.avatar}.png`
    : ''

  // navigate is like a GPS for our website; it helps us move around.
  const navigate = useNavigate()

  const messagesContainerRef = useRef(null)

  const newMessagesContainerRef = useRef(null)

  const messageTextAreaRef = useRef(null)

  const videoIDLERef = useRef()

  const videoTALKRef = useRef()

  //const socket = socketIOClient('https://chatbot3.humanasset.com') //flowise url

  //https://chatbot3.humanasset.com/api/v1/prediction/c7c1a2d8-6ff0-4c0a-aa5a-80c4adb5dfcd

  //const [socketIOClientId, setSocketIOClientId] = useState('')

  // useState is like a personal notepad. It keeps track of info while using the chat.
  const [chatMessages, setChatMessages] = useState([]) // user's chat messages associated to a particular conversation and chatbot
  const [isLoading, setIsLoading] = useState(true) // while the user' chat messages are being fetched loading is set to true
  const [message, setMessage] = useState('') // it is user's prompt from the input field and it is cleared once there was a successful response to sent the promt to chatbot. In the event that the resuest failed, we still keep the message to allow user to retry sending the prompot again without asking him to type the prompt again
  const [textAreaValue, setTextAreaValue] = useState('') // it is also user's prompt from the input field which is set as a value of the input field and this one is cleared once the user has sent the prompt so the input field shows the placeholder again
  const [information, setInformation] = useState('') // information of the response -> the api call was successfull but perhaps no data was found
  const [errorRetrievingMessages, setErrorRetrievingMessages] = useState(false) // string containing the error message when retrieving user's messages fails at any point
  const [error, setError] = useState('') // string containing the error message when sending a prompt to chatbot
  const [previousChatMessagesSent, setPreviousChatMessagesSent] =
    useState(false) // indicator if new messages added to the conversion have been saved or not yet

  const [isChatbotTyping, setIsChatbotTyping] = useState(false) // indicator if chatbot is 'typing' meaning that the response still not come
  const [sendBtnActive, setSendBtnActive] = useState(false) // if there is no prompt in the input field the send button should be disabled

  const [errorSavingChatMessages, setErrorSavingChatMessages] = useState(false) // indicator if there was an error saving the chat messages
  //const [chatMessagesSaved, setChatMessagesSaved] = useState(true) // indicator if messages have been saved which serves different purposes than the error above -> expecially when switching between conversations

  const [isSending, setIsSending] = useState(false) //the response from the API flowise
  const [responseReceived, setResponseReceived] = useState(false) //

  const [tokensChat, setTokensChat] = useState({ in: 0, out: 0, total: 0 })

  const [dropZoneVisible, setDropZoneVisible] = useState(false)
  const [temporaryFile, setTemporaryFile] = useState(null) // Temporarily hold the file
  const [fileBase64String, setFileBase64String] = useState('')

  const [imagesLoaded, setImagesLoaded] = useState(true)

  const [previewImage, setPreviewImage] = useState('')
  const [audioUrl, setAudioUrl] = useState(null)
  const [isRecording, setIsRecording] = useState(false)
  const [audio, setAudio] = useState(null)

  const [isSwitchOn, setIsSwitchOn] = useState(true)

  const [isNewConversation, setIsNewConversation] = useState(true)

  const [transitionClass, setTransitionClass] = useState('fade-in')

  const [isIDLEanimationReady, setisIDLEanimationReady] = useState(false)
  const [isTALKanimationReady, setisTALKanimationReady] = useState(false)

  const [IDLEVideoURL, setIDLEVideoURL] = useState('')
  const [TALKVideoURL, setTALKVideoURL] = useState('')

  const [videoError, setVideoError] = useState('')

  useEffect(() => {}, [isNewConversation])

  // Another robot that fetches messages for our chat when we get back to historical chat -> it fetches data only if the conversation id is present or if the conversation id   changes
  useEffect(() => {
    console.log('I run to check what conversation it is')
    console.log('Conversation state: ', isNewConversation)
    // //RESET THE STATE
    // setIsLoading(true)
    // setMessage('')
    // setTextAreaValue('')
    // setErrorRetrievingMessages(false)
    // setError('')
    // setPreviousChatMessagesSent(false)
    // setIsChatbotTyping(false)
    // setSendBtnActive(false)
    // setErrorSavingChatMessages(false)
    // setChatMessagesSaved(true) // This state needs to be updated here as the messages coming from db are already saved. Thus, to not confuse another robot which is responsible for displaying alert in the browser about unsaved changes here we change the state to true
    // setResponseReceived(false)
    // setIsSending(false)
    // setTokensChat({ in: 0, out: 0, total: 0 })
    // setDropZoneVisible(false)
    // setTemporaryFile(null)
    // setFileBase64String('')
    // setImagesLoaded(true)
    // setPreviewImage('')
    // setAudioUrl(null)
    // setIsRecording(false)
    // setAudio(null)
    // setIsSwitchOn(true)
    // seIsConnectingVideoAnnimation(true)
    // setStreamId('')
    // setSessionId('')
    // setPeerConnection(null)
    // setIdleVideoUrl('')

    const fetchChatMessages = async () => {
      setTransitionClass('fade-out')
      try {
        const response = await getChatMessages(chatBotId, conversationId)
        //console.log('RESPONSE AFTER GETTING MESSAGES IN CHAT WINDOW USE EFFECT')
        console.log(response)
        //console.log(response.message)
        //console.log(response.success)
        //console.log(response.payload[0].FileModels[0])

        if (response.success) {
          setChatMessages(response.payload)
          //console.log('IF')
        } else if (
          !response.success &&
          response.message === 'No messages found'
        ) {
          //console.log('ELSE IF')
          setInformation(response.message)
        }
      } catch (error) {
        //console.log('ERROR')
        setErrorRetrievingMessages(error)
      }
      setTransitionClass('fade-in')
      setIsLoading(false)
    }

    if (conversationId) {
      if (isNewConversation) {
        console.log('IT IS NEW CONVERSATION')
        setIsLoading(false)
        setMessage('')
        setTextAreaValue('')
        setErrorRetrievingMessages(false)
        setError('')
        setPreviousChatMessagesSent(false)
        setIsChatbotTyping(false)
        setSendBtnActive(false)
        setErrorSavingChatMessages(false)
        setChatMessagesSaved(true) // This state needs to be updated here as the messages coming from db are already saved. Thus, to not confuse another robot which is responsible for displaying alert in the browser about unsaved changes here we change the state to true
        setResponseReceived(false)
        setIsSending(false)
        setTokensChat({ in: 0, out: 0, total: 0 })
        setDropZoneVisible(false)
        setTemporaryFile(null)
        setFileBase64String('')
        setImagesLoaded(true)
        setPreviewImage('')
        setAudioUrl(null)
        setIsRecording(false)
        setAudio(null)
        setisTALKanimationReady(false)

        setTALKVideoURL('')
        setIsNewConversation(false)
        setVideoError('')
        console.log('IS loading ? ', isLoading)
      } else {
        console.log('Conversation id: ', conversationId)
        setIsNewConversation(false)
        setIsLoading(true)
        setMessage('')
        setTextAreaValue('')
        setErrorRetrievingMessages(false)
        setError('')
        setPreviousChatMessagesSent(false)
        setIsChatbotTyping(false)
        setSendBtnActive(false)
        setErrorSavingChatMessages(false)
        setChatMessagesSaved(true) // This state needs to be updated here as the messages coming from db are already saved. Thus, to not confuse another robot which is responsible for displaying alert in the browser about unsaved changes here we change the state to true
        setResponseReceived(false)
        setIsSending(false)
        setTokensChat({ in: 0, out: 0, total: 0 })
        setDropZoneVisible(false)
        setTemporaryFile(null)
        setFileBase64String('')
        setImagesLoaded(true)
        setPreviewImage('')
        setAudioUrl(null)
        setIsRecording(false)
        setAudio(null)
        setIsSwitchOn(true)
        fetchChatMessages(chatBotId, conversationId)

        setisTALKanimationReady(false)

        setTALKVideoURL('')
        setVideoError('')
      }
    } else {
      console.log('It is branded new conversation')
      // If there is no conversationId, reset the conversation state
      setIsNewConversation(true)
      setIsLoading(true)
      setMessage('')
      setTextAreaValue('')
      setErrorRetrievingMessages(false)
      setError('')
      setPreviousChatMessagesSent(false)
      setIsChatbotTyping(false)
      setSendBtnActive(false)
      setErrorSavingChatMessages(false)
      setChatMessagesSaved(true) // This state needs to be updated here as the messages coming from db are already saved. Thus, to not confuse another robot which is responsible for displaying alert in the browser about unsaved changes here we change the state to true
      setResponseReceived(false)
      setIsSending(false)
      setTokensChat({ in: 0, out: 0, total: 0 })
      setDropZoneVisible(false)
      setTemporaryFile(null)
      setFileBase64String('')
      setImagesLoaded(true)
      setPreviewImage('')
      setAudioUrl(null)
      setIsRecording(false)
      setAudio(null)
      setIsSwitchOn(true)
      setisIDLEanimationReady(false)
      setVideoError('')
      setChatMessages([
        {
          sender_type: 'chatbot',
          message: chatbotInSession.chatbot_prompt
            ? chatbotInSession.chatbot_prompt
            : 'Hello, how can I assist you today?',
        },
      ])
    }
  }, [conversationId, chatBotId, isNewConversation])

  // This robot warns us if we're about to leave the page but haven't saved our messages.
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      // Check if there's any unsaved messages
      if (!chatMessagesSaved) {
        // Prevent the default unload behavior and show a warning message
        e.preventDefault()
        e.returnValue =
          'You have unsent messages. Are you sure you want to leave?'
      }
    }

    // Add event listener
    window.addEventListener('beforeunload', handleBeforeUnload)

    // Remove event listener on cleanup
    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [chatMessagesSaved]) // Depend on the 'chatMessagesSaved' state

  useEffect(() => {
    if (messagesContainerRef.current) {
      console.log('I SHOULD SCROLL TO THE BOTTOM')
      const messagesContainer = messagesContainerRef.current
      messagesContainer.scrollTop = messagesContainer.scrollHeight
    }
  }, [chatMessages, imagesLoaded])

  useEffect(() => {
    if (newMessagesContainerRef.current) {
      console.log('I SHOULD SCROLL TO THE BOTTOM - new messages ')
      const messagesContainer = newMessagesContainerRef.current
      messagesContainer.scrollTop = messagesContainer.scrollHeight
    }
  }, [chatMessages, imagesLoaded, isChatbotTyping])

  useEffect(() => {
    if (messageTextAreaRef.current) {
      if (textAreaValue === '') {
        messageTextAreaRef.current.style.height = '5rem' // Reset to original height
      } else {
        messageTextAreaRef.current.style.height = 'auto'
        messageTextAreaRef.current.style.height = `${messageTextAreaRef.current.scrollHeight}px`
      }
    }
  }, [textAreaValue])

  useEffect(() => {
    // Call handleSaveConversation whenever chatMessages changes
    if (responseReceived) {
      handleSaveConversation()
      setResponseReceived(false)
    }
  }, [responseReceived])

  // handlePromptChange updates what we're typing into the chat.
  const handlePromptChange = (event) => {
    const textarea = event.target
    textarea.style.height = '5rem' // Reset to original height
    textarea.style.height = `${Math.min(
      textarea.scrollHeight,
      7.5 * parseFloat(getComputedStyle(textarea).fontSize),
    )}px` // Adjust height based on content

    setMessage(event.target.value.replace(/\n+$/, ''))
    setTextAreaValue(event.target.value)
    setSendBtnActive(event.target.value.length > 0 && !isSending)
  }

  // buildRequestBody prepares our message to be sent to the chatbot.
  const buildRequestBody = (
    messages,
    messageToBeSent,
    file = null,
    isRetry = false,
    voiceMessage = false,
    audio = null,
  ) => {
    console.log('BUILDING REQUEST')
    console.log(messageToBeSent)
    if (messages.length > 0) {
      if (isRetry) {
        //console.log('RETRYING TO SEND')
        let clonedMessages = JSON.parse(JSON.stringify(messages))
        clonedMessages.pop()
        const history = clonedMessages.map((message) => ({
          type:
            message.sender_type === 'chatbot' ? 'apiMessage' : 'userMessage',
          message: DOMPurify.sanitize(message.message),
        }))
        return {
          question: DOMPurify.sanitize(messageToBeSent),
          history: history,
        }
      } else {
        if (!voiceMessage) {
          console.log('IT IS NOT RETRYING')
          console.log(messageToBeSent)
          const history = messages.map((message) => ({
            type:
              message.sender_type === 'chatbot' ? 'apiMessage' : 'userMessage',
            message: DOMPurify.sanitize(message.message),
          }))

          if (file !== null) {
            return {
              question: DOMPurify.sanitize(messageToBeSent),
              uploads: [file],
              history: history,
            }
          } else {
            return {
              question: DOMPurify.sanitize(messageToBeSent),
              history: history,
            }
          }
        } else {
          //VOICE MESSAGE
          if (audio) {
            const history = messages.map((message) => ({
              type:
                message.sender_type === 'chatbot'
                  ? 'apiMessage'
                  : 'userMessage',
              message: DOMPurify.sanitize(message.message),
            }))

            if (file !== null) {
              return {
                uploads: [audio, file],
                history: history,
              }
            } else {
              return {
                uploads: [audio],
                history: history,
              }
            }
          }
        }
      }
    }
  }

  const checkTextOrRecording = () => {
    if (isRecording) {
      //console.log('Recording to be sent')
      setIsRecording(false)
      setSendBtnActive(false)
      //handlePromptSend(false)
    } else {
      handlePromptSend()
    }
  }
  useEffect(() => {
    if (audio) {
      handlePromptSend(false)
    }
  }, [audio])

  const getAudio = (recAudio) => {
    //console.log('audioUr is: ', audioUrl)
    //console.log(audioUrl)
    if (audioUrl) {
      //console.log('I am revoking')
      URL.revokeObjectURL(audioUrl)
      setAudioUrl(null)
    }

    if (recAudio) {
      //console.log('REC AUDIO IS: ', recAudio)
      const url = URL.createObjectURL(recAudio)
      setAudioUrl(url)
      setAudio(recAudio)
    } else {
      //console.log('No rec audio')
    }
  }

  const compressAndConvertToBase64 = async (audioBlob) => {
    // Implement audio compression logic here (if needed)

    if (!audioBlob) {
      return null // Handle the case where audioBlob is not provided
    }

    const reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(audioBlob)
      reader.onload = () => resolve(reader.result.split(',')[1]) // Extract base64 string
      reader.onerror = reject
    })
  }

  const handleTalkVideoEnded = () => {
    console.log('TALK video has ended.')
    if (videoIDLERef.current) {
      videoIDLERef.current.style.opacity = 1 // Reset the opacity
    }
    if (videoTALKRef.current) {
      videoTALKRef.current.style.opacity = 0 // Set the opacity
    }
    setError('')
    setPreviousChatMessagesSent(
      conversationId && !previousChatMessagesSent ? true : false,
    )
    setChatMessagesSaved(false)
    setResponseReceived(true)
    finalisingSendPromptToChatbot()
  }
  const finalisingSendPromptToChatbot = () => {
    console.log('Finalising')
    setIsChatbotTyping(false)
    setIsSending(false)
    setTemporaryFile(null)
    setDropZoneVisible(false)
    setMessage('')
    setAudioUrl(null)
  }

  const handlePromptSend = async (textInput = true, isRetry = false) => {
    if (textInput) {
      setTextAreaValue('')
      setSendBtnActive(false)
      setIsSending(true)
      setTemporaryFile(null)
      setDropZoneVisible(false)

      console.log('Message is: ', message)

      if (message !== '' && message !== null) {
        const encoder = encodingForModel('gpt-4')
        const newTokens = encoder.encode(message)

        setTokensChat((prevState) => ({
          ...prevState,
          out: prevState.out + newTokens.length,
          total: prevState.total + newTokens.length,
        }))

        if (!isRetry) {
          // Add user's message only if it's not a retry attempt
          const userMessage = {
            sender_type: 'user',
            message: message,
            file: temporaryFile ? temporaryFile : null,
          }
          setChatMessages((prevMessages) => [...prevMessages, userMessage])
        }

        let file

        if (temporaryFile) {
          file = {
            data: fileBase64String, //base64 string
            type: 'file',
            name: temporaryFile?.name,
            mime: temporaryFile?.type,
          }
        }

        setIsChatbotTyping(true)

        // Build request body based on conversation context
        let request =
          conversationId && !previousChatMessagesSent
            ? buildRequestBody(
                chatMessages,
                message,
                file,
                isRetry,
                false,
                null,
              )
            : temporaryFile
            ? {
                question: message,
                uploads: [file],
              }
            : { question: message }
        try {
          //console.log('REQUEST TO CHATBOT')
          console.log('REQUEST TO CHATBOT', request)
          const response = await sendPromptToChatbot(
            request,
            chatbotInSession.url, //chatbotData
          )

          if (response) {
            const encoder = encodingForModel('gpt-4')
            const newTokens = encoder.encode(response.text)
            setTokensChat((prevState) => ({
              ...prevState,
              in: prevState.in + newTokens.length,
              total: prevState.total + newTokens.length,
            }))

            const apiMessage = {
              sender_type: 'chatbot',
              message: DOMPurify.sanitize(response.text),
            }

            //CALL DID GENEARTE VIDEO

            if (isSwitchOn && chatbotInSession.streaming) {
              const textToDID = apiMessage.message

              let name = chatbotInSession.avatar.replace('Circle', '')

              const response = await fetchDIDtalk(name, textToDID)

              console.log(response)
              console.log(response)

              if (response.result && response.result.result_url) {
                // Access the result URL
                const resultUrl = response.result.result_url
                console.log('Result URL:', resultUrl)

                // Render video
                if (videoTALKRef.current) {
                  videoTALKRef.current.style.opacity = 1 // Set the opacity
                }
                if (videoIDLERef.current) {
                  videoIDLERef.current.style.opacity = 0 // Set the opacity to 50%
                }

                setTALKVideoURL(resultUrl)
                setisTALKanimationReady(true)
                setIsChatbotTyping(false)
              } else if (response.error) {
                setVideoError(
                  `${response.error} Try to refresh the page or switch off the video streaming to chat with ${chatbotInSession.name}`,
                )

                // No video
                // If conversation id redirect to that conversation or new chat
                let conversationNew
                if (conversationId) {
                  conversationNew = false
                } else {
                  conversationNew = true
                }
                console.log(
                  `There was a problem generating the video. The conversation is new: ${conversationNew}, ${
                    conversationId
                      ? `Conversation ID is: ${conversationId}`
                      : ''
                  }`,
                )
                finalisingSendPromptToChatbot()
              }
              setChatMessages((prevMessages) => [...prevMessages, apiMessage])

              // console.log('BEFORE PLAYING RESPONSE')
              // // const url =
              //'https://d-id-talks-prod.s3.us-west-2.amazonaws.com/auth0%7C66672c787a2c3c90d16cbad2/tlk_a8XjURowjBJ4pxYmkK7B6/1718189533272.mp4?AWSAccessKeyId=AKIA5CUMPJBIK65W6FGA&Expires=1718275954&Signature=S0i%2B0OZth4Abn0w%2Fj8MKHLB%2Bd9w%3D'
              // if (videoIDLERef.current) {
              //   videoIDLERef.current.style.opacity = 0.5 // Set the opacity to 50%
              // }
              // setTALKVideoURL(url)
              // setisTALKanimationReady(true)

              //Generate talk
            } else {
              setChatMessages((prevMessages) => [...prevMessages, apiMessage])
              setError('')
              setPreviousChatMessagesSent(
                conversationId && !previousChatMessagesSent ? true : false,
              )
              setChatMessagesSaved(false)
              setResponseReceived(true)
              finalisingSendPromptToChatbot()
            }
          } else {
            setError(`There was an error while sending your message. ${error}`)

            finalisingSendPromptToChatbot()
          }
        } catch (error) {
          setError(`There was an error while sending your message. ${error}`)

          finalisingSendPromptToChatbot()
        }
      }
    } else {
      if (audio) {
        setTextAreaValue('')
        setSendBtnActive(false)
        setIsSending(true)
        setTemporaryFile(null)
        setDropZoneVisible(false)
        // console.log('Before sending but in sending part')
        try {
          if (!isRetry) {
            // Add user's message only if it's not a retry attempt
            const userMessage = {
              sender_type: 'user',
              message: 'Transcription in process... Please wait.',
              file: temporaryFile ? temporaryFile : null,
            }
            setChatMessages((prevMessages) => [...prevMessages, userMessage])
          }
          const base64String = await compressAndConvertToBase64(audio)
          //console.log('Audio base64 is: ', base64String)
          // Use the base64String further...

          let audioFile = {
            data: `data:audio/webm;codecs=opus;base64,${base64String}`, //base64 string
            type: 'audio',
            name: audio?.name ? audio.name : 'audio.wav',
            mime: 'audio/webm',
            //mime: audio?.type ? audio.type : 'audio/webm',
          }

          let file

          if (temporaryFile) {
            file = {
              data: fileBase64String, //base64 string
              type: 'file',
              name: temporaryFile?.name,
              mime: temporaryFile?.type,
            }
          }

          setIsChatbotTyping(true)

          // Build request body based on conversation context
          let request =
            conversationId && !previousChatMessagesSent
              ? buildRequestBody(
                  chatMessages,
                  message,
                  file,
                  isRetry,
                  true,
                  audioFile,
                )
              : temporaryFile
              ? {
                  uploads: [audioFile, file],
                }
              : { uploads: [audioFile] }

          //console.log(request)

          const response = await sendPromptToChatbot(
            request,
            chatbotInSession.url, //chatbotData
          )

          if (response) {
            const encoder = encodingForModel('gpt-4')
            //console.log(response)
            //console.log(response.question)
            //console.log(response.text)
            const newChatbotTokens = encoder.encode(response.text)
            setTokensChat((prevState) => ({
              ...prevState,
              in: prevState.in + newChatbotTokens.length,
              total: prevState.total + newChatbotTokens.length,
            }))

            const newUserTokens = encoder.encode(response.question)
            setTokensChat((prevState) => ({
              ...prevState,
              out: prevState.out + newUserTokens.length,
              total: prevState.total + newUserTokens.length,
            }))

            const apiMessage = {
              sender_type: 'chatbot',
              message: DOMPurify.sanitize(response.text),
            }

            const userMessage = {
              sender_type: 'user',
              message: DOMPurify.sanitize(response.question),
              file: temporaryFile ? temporaryFile : null,
            }

            //CALL DID GENEARTE VIDEO
            if (isSwitchOn && chatbotInSession.streaming) {
              const textToDID = apiMessage.message

              let name = chatbotInSession.avatar.replace('Circle', '')

              const response = await fetchDIDtalk(name, textToDID)

              console.log(response)
              console.log(response)

              if (response.result && response.result.result_url) {
                // Access the result URL
                const resultUrl = response.result.result_url
                console.log('Result URL:', resultUrl)

                // Render video
                if (videoTALKRef.current) {
                  videoTALKRef.current.style.opacity = 1 // Set the opacity
                }
                if (videoIDLERef.current) {
                  videoIDLERef.current.style.opacity = 0 // Set the opacity to 50%
                }

                setTALKVideoURL(resultUrl)
                setisTALKanimationReady(true)
                setIsChatbotTyping(false)
              } else if (response.error) {
                setVideoError(
                  `${response.error} Try to refresh the page or switch off the video streaming to chat with ${chatbotInSession.name}`,
                )

                // No video
                // If conversation id redirect to that conversation or new chat
                let conversationNew
                if (conversationId) {
                  conversationNew = false
                } else {
                  conversationNew = true
                }
                console.log(
                  `There was a problem generating the video. The conversation is new: ${conversationNew}, ${
                    conversationId
                      ? `Conversation ID is: ${conversationId}`
                      : ''
                  }`,
                )
                finalisingSendPromptToChatbot()
              }

              // console.log('BEFORE PLAYING RESPONSE')
              // // const url =
              //'https://d-id-talks-prod.s3.us-west-2.amazonaws.com/auth0%7C66672c787a2c3c90d16cbad2/tlk_a8XjURowjBJ4pxYmkK7B6/1718189533272.mp4?AWSAccessKeyId=AKIA5CUMPJBIK65W6FGA&Expires=1718275954&Signature=S0i%2B0OZth4Abn0w%2Fj8MKHLB%2Bd9w%3D'
              // if (videoIDLERef.current) {
              //   videoIDLERef.current.style.opacity = 0.5 // Set the opacity to 50%
              // }
              // setTALKVideoURL(url)
              // setisTALKanimationReady(true)
              setChatMessages((prevMessages) => {
                const updatedMessages = prevMessages.slice(0, -1) // Remove the last item
                return [...updatedMessages, userMessage, apiMessage]
              })
              //Generate talk
            } else {
              setChatMessages((prevMessages) => {
                const updatedMessages = prevMessages.slice(0, -1) // Remove the last item
                return [...updatedMessages, userMessage, apiMessage]
              })
              setError('')
              setPreviousChatMessagesSent(
                conversationId && !previousChatMessagesSent ? true : false,
              )
              setChatMessagesSaved(false)
              setResponseReceived(true)
              finalisingSendPromptToChatbot()
            }
          } else {
            setError(`There was an error while sending your message. ${error}`)

            finalisingSendPromptToChatbot()
          }
        } catch (error) {
          setError(`There was an error while sending your message. ${error}`)

          finalisingSendPromptToChatbot()
        }
      } else {
        console.log('Audio blob not available')
      }
    }
  }

  // handleRetrySendMessage lets us try sending a message again if something went wrong.
  const handleRetrySendMessage = () => {
    //handlePromptSend(true)
  }

  // handleSaveConversation saves our chat so we can look at it later.
  const handleSaveConversation = async () => {
    let response
    const messagesToSave = chatMessages.slice(-2)

    const formData = new FormData()

    const cleanedMessages = messagesToSave.map((message) => {
      const cleanedMessage = { ...message }
      if (message.file) {
        const fieldName = message.sender_type === 'user' ? 'user' : 'chatbot'
        formData.append(fieldName, message.file, message.file.name)
      }
      delete cleanedMessage.file
      return cleanedMessage
    })

    // for (let pair of formData.entries()) {
    //   console.log('Pair ' + pair[0] + ', ' + pair[1])
    // }
    if (conversationId) {
      formData.append(
        'chatMessages',
        JSON.stringify({ messages: cleanedMessages }),
      )
    } else {
      formData.append(
        'chatMessages',
        JSON.stringify({
          messages: [
            {
              sender_type: 'chatbot',
              message: chatbotInSession.chatbot_prompt,
            },
            ...cleanedMessages,
          ],
        }),
      )
    }

    // const messagesToSaveJSON = JSON.stringify(messagesToSave)
    // //const messages = JSON.stringify(chatMessages)
    // const messageObject = { messages: messagesToSaveJSON }
    let title // Declare title variable outside the try...catch blocks
    try {
      if (conversationId) {
        formData.append('conversationId', conversationId)
        formData.append('tokensChat', JSON.stringify(tokensChat))

        // for (let pair of formData.entries()) {
        //   console.log('Pair 2 ' + pair[0] + ', ' + pair[1])
        // }
        response = await updateChatMessages(formData)
      } else {
        const firstMessage = chatMessages.find(
          (message) => message.sender_type === 'user',
        )?.message

        //send first user's message to title geny
        try {
          // Attempt to generate a title
          const res = await generateTitle({ question: firstMessage })
          title = res.text // Use the generated title
        } catch (generateTitleError) {
          // Fallback to manual title generation if generateTitle fails
          title = chatMessages
            .find((message) => message.sender_type === 'user')
            ?.message.split(' ')
            .slice(0, 3)
            .join(' ')
            .concat('...')
        }

        formData.append('chatBotId', chatBotId)
        formData.append('title', title)
        formData.append('tokensChat', JSON.stringify(tokensChat))
        response = await createNewChat(formData)
      }

      // Check response
      if (response?.status === 200) {
        if (response.data.success) {
          //alert(successMessage)
          if (response.data.payload.length > 0) {
            if (response.data.result) {
              const arrayOfFileModels = response.data.result

              console.log("'''''''''''''''''''''''''''''''''''''")
              console.log(arrayOfFileModels)
              // check sender type
              //if sender type is user update the pre last message about file path and file name and delete file property
              //chatbot
              //do the same but for the last message

              const userMessageIndex = chatMessages.length - 2
              const chatbotMessageIndex = chatMessages.length - 1

              const userMessage = chatMessages[userMessageIndex]
              const chatbotMessage = chatMessages[chatbotMessageIndex]

              console.log('userMessage')
              console.log(userMessage)
              console.log('chatbotMessage')
              console.log(chatbotMessage)

              let newUserMessage
              let newChatbotMessage

              let userFiles = []
              let chatbotFiles = []

              if (conversationId) {
                arrayOfFileModels.forEach((element, index, array) => {
                  if (element.sender_type === 'user') {
                    console.log('User object')
                    userFiles.push({
                      path: element.filePath + '\\' + element.fileName,
                      name: element.fileName,
                    })
                  } else {
                    console.log('Chatbot object')
                    chatbotFiles.push({
                      path: element.filePath + '\\' + element.fileName,
                      name: element.fileName,
                    })
                  }
                })

                delete userMessage.file
                newUserMessage = { ...userMessage, FileModels: userFiles }

                delete chatbotMessage.file
                newChatbotMessage = {
                  ...chatbotMessage,
                  FileModels: chatbotFiles,
                }

                console.log('New user message', newUserMessage)
                console.log('New chatbot message', newChatbotMessage)

                setChatMessages((prevState) => {
                  const updatedMessages = [...prevState]

                  if (updatedMessages.length >= 2) {
                    updatedMessages[userMessageIndex] = newUserMessage
                    updatedMessages[chatbotMessageIndex] = newChatbotMessage
                  }

                  return updatedMessages
                })
              }
            }
          }
          setChatMessagesSaved(true)
          if (!conversationId) {
            navigate(
              `/chatbot-dashboard/${chatBotId}/${response.data.payload[0]?.conversation_id}`,
              { replace: true },
            )
            // history.push(
            //   `/chatbot-dashboard/${chatBotId}/${response.data.payload[0]?.conversation_id}`,
            // )
          }
        } else {
          setErrorSavingChatMessages(true)
        }
      } else {
        setErrorSavingChatMessages(true)
      }
    } catch (error) {
      setErrorSavingChatMessages(true)
    } finally {
    }
  }

  const handleKeyDown = (event) => {
    // Check if the key pressed is 'Enter', the Shift key is not held down, and not currently sending a message
    if (event.key === 'Enter' && !event.shiftKey && !isSending) {
      event.preventDefault() // Prevents the default action (new line)
      // Call the function to send the message
      checkTextOrRecording()
    }
  }

  const toggleDropZoneVisibility = () => {
    if (!temporaryFile) {
      setDropZoneVisible(!dropZoneVisible)
    }
  }

  const handleFileUpload = (file) => {
    if (file) {
      // Check if file is not null
      if (file.type.startsWith('image/')) {
        new Compressor(file, {
          quality: 0.6, // Reduce the image quality to 60%
          success(compressedResult) {
            const reader = new FileReader()
            reader.readAsDataURL(compressedResult)
            reader.onload = () => {
              setFileBase64String(reader.result)
              setTemporaryFile(compressedResult)
            }
            reader.onerror = (error) => {
              console.error('Error reading compressed image:', error)
            }
          },
          error(err) {
            console.error('Compression error:', err.message)
          },
        })
      } else {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          setFileBase64String(reader.result)
          setTemporaryFile(file)
        }
        reader.onerror = (error) => {
          console.error('Error converting file to base64:', error)
        }
      }
    } else {
      // Clear any existing file information if no file is present
      setFileBase64String(null)
      setTemporaryFile(null)
    }
  }

  useEffect(() => {
    if (temporaryFile) {
      const objectURL = URL.createObjectURL(temporaryFile)
      setPreviewImage(objectURL)
    }
    return () => URL.revokeObjectURL(temporaryFile)
  }, [temporaryFile])

  const handleRecordingStart = () => {
    setIsRecording(true)
    URL.revokeObjectURL(audioUrl)
    setAudioUrl(null)
    setSendBtnActive(true)
    setMessage('')
    setTextAreaValue('')
    setAudio(null)
  }

  const handleBtnClickForVideoError = () => {
    setIsSwitchOn(false)
    setVideoError(null) // Clear the error after handling it
  }

  const handleSwitchChange = (event) => {
    setIsSwitchOn(event.target.checked)
  }

  useEffect(() => {
    const fetchIdleVideo = async (name) => {
      const response = await fetchIDLEvideo(name)

      if (response.error) {
        // Handle error
        setVideoError(
          `${response.error} Try to refresh the page or switch off the video streaming to chat with ${chatbotInSession.name}`,
        )
        //isSwitchOn(false)
      } else if (response.result) {
        console.log('response: ', response.result.result_url)

        setIDLEVideoURL(response.result.result_url)
        setisIDLEanimationReady(true)
      } else if (response.videoUrl) {
        console.log('response: ', response.videoUrl)

        setIDLEVideoURL(response.videoUrl)
        setisIDLEanimationReady(true)
      }
    }

    if (isSwitchOn && chatbotInSession.streaming) {
      console.log('I am in the useEffect of isSwitchOn')
      // Fetch idle animation
      const name = chatbotInSession.avatar.replace('Circle', '')
      console.log(name) // Output: name
      fetchIdleVideo(name)
    } else {
      console.log('No stream available')
    }
  }, [isSwitchOn, conversationId, chatbotInSession])

  const renderMessages = () => (
    <div ref={newMessagesContainerRef} className={styles.newMessageContainer}>
      {Array.isArray(chatMessages) &&
        chatMessages.map((message, index) => (
          <div
            key={index}
            className={
              message.sender_type === 'chatbot'
                ? styles.chatbotMessage
                : styles.userMessage
            }
          >
            <img
              className={styles.avatar}
              alt="avatar"
              src={
                message.sender_type === 'chatbot' ? imagePath : imagePathUser
              }
            />
            {message.sender_type === 'chatbot' ? (
              <span className={styles.message}>
                <ReactMarkdown
                  components={{
                    // Inline customization for links
                    a: ({ node, ...props }) => (
                      <a
                        {...props}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      />
                    ),
                  }}
                >
                  {message.message}
                </ReactMarkdown>
                {message.FileModels?.length > 0 && <div>IMAGE PRESENT</div>}
              </span>
            ) : (
              <span className={styles.message}>
                <ReactMarkdown
                  components={{
                    // Inline customization for links
                    a: ({ node, ...props }) => (
                      <a
                        {...props}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                      />
                    ),
                  }}
                >
                  {message.message}
                </ReactMarkdown>
                {message.FileModels?.length > 0 &&
                  message.FileModels.map((file, idx) => (
                    <ImageContainer
                      key={idx}
                      fileDetails={file}
                      onImageLoad={() => setImagesLoaded((prev) => !prev)}
                    />
                  ))}
                {message.hasOwnProperty('file') && message.file !== null && (
                  <img
                    src={previewImage}
                    alt={'user uploaded file'}
                    style={{ maxWidth: '30%' }}
                  ></img>
                )}
              </span>
            )}
          </div>
        ))}
      {isChatbotTyping && (
        <div className={styles.chatbotMessage}>
          <img className={styles.avatar} alt="avatar" src={imagePath} />
          <span className={styles.dots}>
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </span>
        </div>
      )}
    </div>
  )

  return (
    <div className={styles.chatWindow}>
      {isLoading && conversationId ? (
        <div>Loading...</div>
      ) : (
        <>
          <div
            className={`${styles.messagesContainer} ${styles[transitionClass]}`}
            ref={messagesContainerRef}
          >
            <>
              {errorRetrievingMessages ? (
                <ErrorCard
                  message="An unexpected error occurred while retrieving the previous chat messages. Please try again later."
                  to={`/chatbot-dashboard/${chatBotId}/${conversationId}`}
                  name="RETRY"
                />
              ) : (
                <>
                  <div className={styles.parentContainer}>
                    <span className={styles.chatbotTitle}>
                      {chatbotInSession.title}
                    </span>
                    {chatbotInSession.streaming && (
                      <VideoSwitch
                        checked={isSwitchOn}
                        onChange={handleSwitchChange}
                      />
                    )}
                  </div>
                  {chatbotInSession.streaming && isSwitchOn ? (
                    <>
                      <div className={styles.loadingContainer}>
                        {!isIDLEanimationReady && (
                          <div className={styles.overlay}>
                            <LoadingSpinner />
                            <p className={styles.chatbotTitle}>Connecting</p>
                          </div>
                        )}
                        <div className={styles.videoContainer}>
                          {isIDLEanimationReady && IDLEVideoURL && (
                            <video
                              className={styles.video}
                              ref={videoIDLERef}
                              width="400"
                              height="400"
                              autoPlay
                              playsInline
                              loop
                              src={IDLEVideoURL}
                              muted // Add this if you need to mute the video for autoplay to work in some browsers
                            ></video>
                          )}
                          {isTALKanimationReady && TALKVideoURL && (
                            <video
                              className={styles.video}
                              ref={videoTALKRef}
                              width="400"
                              height="400"
                              autoPlay
                              playsInline
                              src={TALKVideoURL}
                              onEnded={handleTalkVideoEnded}
                              // Add this if you need to mute the video for autoplay to work in some browsers
                            ></video>
                          )}
                        </div>
                      </div>
                      {renderMessages()}
                    </>
                  ) : (
                    renderMessages()
                  )}

                  {error && (
                    <button
                      className={styles.retryBtn}
                      onClick={handleRetrySendMessage}
                    >
                      Retry <br /> {error}
                    </button>
                  )}
                  {errorSavingChatMessages && (
                    <ErrorCard
                      message="An unexpected error occurred while trying to save your conversation"
                      name="OK"
                      action="CONFIRM"
                      handleBtnClick={() => setErrorSavingChatMessages(false)}
                    />
                  )}
                  {videoError && (
                    <ErrorCard
                      message={videoError}
                      name="OK"
                      action="CONFIRM"
                      handleBtnClick={() => handleBtnClickForVideoError()}
                    />
                  )}
                </>
              )}
            </>
          </div>
          {dropZoneVisible && (
            <DropZoneInput onFileAccepted={handleFileUpload} />
          )}
          <div className={styles.footer}>
            {isSwitchOn &&
              chatbotInSession.streaming &&
              !isIDLEanimationReady && <div className={styles.overlay2} />}
            <div>
              <span
                onClick={toggleDropZoneVisibility}
                className={`material-symbols-outlined ${styles.materialSymbolsOutlined}`}
              >
                add_photo_alternate
              </span>
            </div>
            {!isRecording && (
              <textarea
                ref={messageTextAreaRef}
                className={styles.inputField}
                onChange={handlePromptChange}
                value={textAreaValue}
                placeholder="Type here..."
                onKeyDown={handleKeyDown}
              />
            )}
            <VoiceRecorder2
              onStartRecording={handleRecordingStart}
              isRecording={isRecording}
              getAudio={getAudio}
            />
            <SendButton
              onClick={checkTextOrRecording}
              disabled={!sendBtnActive}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ChatWindow

//  {
//    audioUrl && (
//      <audio controls src={audioUrl}>
//        Your browser does not support the audio element.
//      </audio>
//    )
//  }
