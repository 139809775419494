import styles from '../../styles/SectionFive.module.css'
import GeneralButton from '../GeneralButton'
import { forwardRef } from 'react'

const SectionFive = forwardRef((props, ref) => {
  return (
    <div ref={ref} id="section5" className={styles.Container}>
      <div className={styles.simple_radial}>
        <div className={styles.contentWrapper}>
          <div className={styles.text}>Book a Demo</div>
          <div className={styles.secondaryText}>
            Let's Explore Your Vision Together: Share Your Project, Pilot, or
            Idea with Us!
          </div>
          <div className={styles.secondaryText}>
            Contact us at:
            <a className={styles.linkText} href=" mailto:info@humanasset.com">
              info@humanasset.com
            </a>
          </div>
        </div>
      </div>
    </div>
  )
})

export default SectionFive

//  <div className={styles.buttonContainer}>
//           <GeneralButton
//             text="Request a demo today!"
//             to="/chatbot-dashboard"
//           />
//         </div>
