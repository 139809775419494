import api from './APIconfig'

export const getUsersData = async (withToken = true) => {
  try {
    const response = await api.get('/user/getUsersData', {
      withToken: withToken,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const getAllCompanyChatbots = async (withToken = true) => {
  try {
    const response = await api.get('/companyAdmin/getCompanyChatbots', {
      withToken: withToken,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const addNewUser = async (userData, withToken = true) => {
  try {
    const response = await api.post('/user/addNewUser', {
      ...userData,
      withToken: withToken,
    })
    return response.data
  } catch (error) {
    // Check if the error response has a specific format and message
    if (error.response && error.response.data && error.response.data.message) {
      // If the backend sends a specific message, use that
      throw new Error(error.response.data.message)
    } else {
      // If there's no specific message, throw a general error
      throw new Error('Failed to add new user. Please try again.')
    }
  }
}

export const deleteUser = async (userId, withToken = true) => {
  try {
    const response = await api.post('/user/deleteUser', {
      userId,
      withToken: withToken,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
export const updateUser = async (userData, withToken = true) => {
  try {
    const response = await api.post('/user/updateUser', {
      userData,
      withToken: withToken,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
