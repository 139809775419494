import { useState, useEffect } from 'react'
import { getTokensInformationFromDB } from '../../services/API/TokensInformationServices'
import { getUsersTokensUsageFromDB } from '../../services/API/TokensInformationServices'
import styles from '../../styles/ChatTokenTrackerPage.module.css'
import ChatbotsTokensUsagePieChart from '../dataAnalytics/ChatbotsTokensUsagePieChart'
import ChatbotsInformation from '../dataAnalytics/ChatbotsInformation'
import ChatbotsTokensUsageBarChart from '../dataAnalytics/ChatbotsTokenUsageBarChart'
import UsersTokenUsage from '../dataAnalytics/UsersTokenUsage'
import { pageVariants, pageTransition } from '../../animations/pageTransitions'
import { motion } from 'framer-motion'

const ChatTokenTrackerPage = () => {
  //no chatbots registered with the company
  const [chatbotsTokenUsage, setChatbotsTokenUsage] = useState([])
  const [chatbotsTokenUsageInfo, setChatbotsTokenUsageInfo] = useState([])
  const [chatbotsTokenUsageInfoGraphs, setChatbotsTokenUsageInfoGraphs] =
    useState('')

  const [usersTokensUsage, setUsersTokensUsage] = useState([])
  const [usersTokensUsageInfo, setUsersTokensUsageInfo] = useState('')

  useEffect(() => {
    const getTokensInformation = async () => {
      try {
        const tokensData = await getTokensInformationFromDB()
        if (
          tokensData.success &&
          tokensData.payload &&
          tokensData.payload.length > 0
        ) {
          const tokensAvailable = tokensData.payload.some(
            (obj) => obj.grand_total > 0,
          )

          if (tokensAvailable) {
            setChatbotsTokenUsage(tokensData.payload)
          } else {
            //tokens are 0
            setChatbotsTokenUsage(tokensData.payload)
            setChatbotsTokenUsageInfoGraphs('There is no data to display.')
          }
        } else {
          // Scenario 3: No chatbots assigned
          setChatbotsTokenUsageInfo('No chatbots registered')
        }
      } catch (error) {
        // Handle errors
        setChatbotsTokenUsageInfo('Error fetching token usage data.')
      }
    }

    const getUsersTokensUsage = async () => {
      try {
        const usersTokensData = await getUsersTokensUsageFromDB()
        if (usersTokensData.success) {
        }
        setUsersTokensUsage(usersTokensData)
      } catch (error) {
        setUsersTokensUsageInfo('Error fetching token usage data.')
      }
    }
    getTokensInformation()
    getUsersTokensUsage()
  }, [])

  return (
    <motion.div
      style={{ width: '100%', height: '100%' }}
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      transition={pageTransition}
    >
      <div className={styles.container}>
        <div className={styles.pageTitle}>Chatbot Token Tracker</div>
        <div className={styles.titleLine}></div>
        <div>
          <ChatbotsInformation
            data={chatbotsTokenUsage}
            message={chatbotsTokenUsageInfo}
          />
          <ChatbotsTokensUsagePieChart
            data={chatbotsTokenUsage}
            message={chatbotsTokenUsageInfoGraphs}
          />
          <ChatbotsTokensUsageBarChart
            data={chatbotsTokenUsage}
            message={chatbotsTokenUsageInfoGraphs}
          />
          <UsersTokenUsage
            data={usersTokensUsage}
            message={usersTokensUsageInfo}
          />
        </div>
      </div>
    </motion.div>
  )
}
export default ChatTokenTrackerPage
