import { openDB } from 'idb'

// IndexedDB setup and helper functions
const dbPromise = openDB('chatbot-db', 1, {
  upgrade(db) {
    db.createObjectStore('animations')
  },
})

export const storeAnimation = async (key, blob) => {
  const db = await dbPromise
  await db.put('animations', blob, key)
}

export const getAnimation = async (key) => {
  const db = await dbPromise
  return db.get('animations', key)
}
