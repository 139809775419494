export const data = [
  {
    title: 'AiHub End Users View',
    text: [
      'Provides a unified dashboard for accessing and managing a comprehensive suite of chatbots.',
      'Offers a streamlined, personalized interface for enhanced user experience.',
    ],
    picture:
      process.env.PUBLIC_URL + '/images/landingPage/section3/endUserView.png',
  },
  {
    title: 'AiHub: Chatbot Interface',
    text: [
      'Enables users to directly engage with a variety of AI chatbots.',
      'Supports initiating new dialogues or continuing existing ones seamlessly.',
      'Facilitates connections through APIs to a wide range of LLMs, including OpenAI GPT, Llama, and others, catering to both proprietary and open-source preferences.',
    ],
    picture:
      process.env.PUBLIC_URL + '/images/landingPage/section3/chatInterface.png',
  },
  {
    title: 'IT Professionals or Citizen Developers View',
    text: [
      `Integrates large language models (LLMs) into apps using Flowise's drag-and-drop GUI, fostering innovation.`,
      'Simplifies generative AI application, enhancing chatbot creation, search functionality, process automation, and the development of complex component sequences.',
      'Enables complete customization for tailoring solutions to specific needs.',
    ],
    picture:
      process.env.PUBLIC_URL +
      '/images/landingPage/section3/citizenDevelopment.png',
  },
  {
    title: 'Chatbot Lab Features',
    text: [
      'Enables intricate LLM connections with features like memory and moderation tools, supporting both open-source and proprietary models.',
      'Offers advanced prompt engineering and autonomous agent development for varied tasks.',
      'Provides robust integration for extending LLM functionalities into diverse applications.',
    ],
    picture:
      process.env.PUBLIC_URL +
      '/images/landingPage/section3/chatbotLabFeatures.png',
  },
  {
    title: 'Use Pre-Made Templates',
    text: [
      'Accelerates development and simplifies the process, making advanced technology accessible to a wider audience.',
      'Ensures best practices and consistency across projects, while also allowing for customization and quick prototyping.',
      'Supports scalable solutions and reduces the potential for errors, streamlining the development lifecycle.',
    ],
    picture:
      process.env.PUBLIC_URL +
      '/images/landingPage/section3/preMadeTemplates.png',
  },
  {
    title: 'User Management & Analytics',
    text: [
      'Facilitates the creation and suspension of user accounts, along with the allocation of chatbots to specific users within an organization.',
      'Tracks usage at both team and individual levels, providing in-depth analytics on chatbot interactions and user engagement.',
    ],
    picture:
      process.env.PUBLIC_URL + '/images/landingPage/section3/userAnalytics.png',
  },
]
