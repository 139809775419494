import { BarChart } from '@mui/x-charts/BarChart'
import { axisClasses } from '@mui/x-charts'
import styles from '../../styles/ChatbotsTokenUsageBarChart.module.css'
import { useTheme } from '../../contexts/ThemeContext'
import Container from '../Container'

const ChatbotsTokensUsageBarChart = ({ data, message }) => {
  const isDataAvailable = data && data.length > 0

  const { theme } = useTheme() // Using ThemeContext

  const color = theme === 'dark' ? 'white' : 'black'

  const filteredData = isDataAvailable
    ? data.filter((chatbot) => parseInt(chatbot.grand_total) > 0)
    : []


  const hasFilteredData = filteredData.length > 0

  const xAxisLabels = filteredData?.map((chatbot) => chatbot.name || 'Unnamed')
  const totalSeries = filteredData?.map((chatbot) =>
    parseInt(chatbot.grand_total),
  )
  const inSeries = filteredData?.map((chatbot) => parseInt(chatbot.total_in))
  const outSeries = filteredData?.map((chatbot) => parseInt(chatbot.total_out))

  return (
    <Container
      text="Active Chatbots Token Usage"
      description="Navigate through the comprehensive bar chart for a detailed breakdown of token distribution per chatbot. Each bar cluster represents an active chatbot, with the 'Total' bar showing the cumulative token interactions. The 'In' bar measures user engagement, indicating the number of times users have activated a chatbot. Conversely, the 'Out' bar provides insights into the chatbot's output, highlighting the extent of its proactive interactions with users."
    >
      {isDataAvailable && hasFilteredData ? (
        <div className={styles.chartContainer}>
          <BarChart
            padding-top={{ top: 60 }}
            margin={{
              top: 60,
              bottom: 80,
            }}
            yAxis={[
              {
                label: 'Token Usage',
                labelStyle: {
                  fontSize: 18,
                  fill: color, // Set the color of the Y-axis label
                },
              },
            ]}
            sx={{
              pt: 3,
              '& .MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                strokeWidth: '0.4',
                fill: color,
              },
              '.css-1k2u9zb-MuiChartsAxis-root .MuiChartsAxis-tick': {
                stroke: color, // Change the color of the tick marks
              },
              // change bottom label styles
              '& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
                strokeWidth: '0.5',
                fill: color,
              },
              // bottomAxis Line Styles
              '& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
                stroke: color,
                strokeWidth: 0.4,
              },
              // leftAxis Line Styles
              '& .MuiChartsAxis-left .MuiChartsAxis-line': {
                stroke: color,
                strokeWidth: 0.4,
              },
              [` .${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)',
              },
            }}
            xAxis={[
              {
                scaleType: 'band',
                data: xAxisLabels,
                label: 'Chatbots',
                labelStyle: {
                  fontSize: 18,
                  fill: color, // Set the color of the Y-axis label
                },
              },
            ]}
            series={[
              { name: 'Total', data: totalSeries, label: 'Total' },
              { name: 'In', data: inSeries, label: 'In' },
              { name: 'Out', data: outSeries, label: 'Out' },
            ]}
            slotProps={{
              legend: {
                labelStyle: {
                  fontSize: 14,
                  fill: color,
                },
              },
              label: {
                labelStyle: {
                  fill: color,
                },
              },
              xAxisLabels: {
                labelStyle: {
                  fill: color,
                },
              },
            }}
          />
        </div>
      ) : (
        <p className={styles.infoText}>{message}</p>
      )}
    </Container>
  )
}

export default ChatbotsTokensUsageBarChart
