import api from './APIconfig'

export const getTokensInformationFromDB = async (withToken = true) => {
  try {
    // The `withToken` flag is passed to include/exclude the authorization token in the request header.
    const response = await api.get('/tokens/getTokensInformation', {
      withToken: withToken,
    })
    return response.data // Returns the data from the response.
  } catch (error) {
    console.error(error)
    throw error // Propagates the error for further handling.
  }
}

export const getUsersTokensUsageFromDB = async (withToken = true) => {
  try {
    const response = await api.get('/tokens/getUserstokensUsage', {
      withToken: withToken,
    })
    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
