import styles from '../styles/UserCard.module.css'

const UserCard = ({ user }) => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.userDetails}>Username:</div>
        <div className={styles.userDetails}>{user.username}</div>
      </div>
      <div className={styles.info}>
        <div className={styles.userDetails}>Email:</div>
        <div className={styles.userDetails}>{user.email}</div>
      </div>
      <div>
        {user.chatbots.map((chatbot) => (
          <div key={chatbot.chatbot_id} className={styles.chatbot}>
            <img
              className={styles.chatbotAvatar}
              src={`${process.env.PUBLIC_URL}/images/avatars/${chatbot.avatar}.png`}
              alt={chatbot.chatbot_name}
            />
            <div>
              <div className={styles.chatbotDetails}>
                {chatbot.chatbot_name}
              </div>
              <div className={styles.chatbotDetails}>{chatbot.title}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default UserCard
