import styles from '../../styles/SectionThree.module.css'
import Carousel from '../Carousel'
import { data } from '../Data'
import { forwardRef } from 'react'

const SectionThree = forwardRef((props, ref) => {
  return (
    <div ref={ref} id="section3" className={styles.Container}>
      <div className={styles.simple_radial}>
        <div className={styles.contentWrapper}>
          <div className={styles.text}>
            Features and Benefits: Elevate Your Business
          </div>
          <Carousel items={data} />
        </div>
      </div>
    </div>
  )
})

export default SectionThree
