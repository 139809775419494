import { useState, useEffect } from 'react'
import { getImage } from '../../services/API/ChatServices'

const ImageContainer = ({ fileDetails, onImageLoad }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [error, setError] = useState('')

  useEffect(() => {
    const fetchImage = async () => {
      setIsLoading(true)

      try {
        const response = await getImage({
          path: fileDetails.path,
        })

        if (response.data.success) {
          setImageSrc(`data:image/jpeg;base64,${response.data.image}`)
          setError('')
        } else {
          console.log('Error: failed to load image')
          setError('Error: failed to load image')
        }
      } catch (error) {
        console.error('Error fetching image:', error)
        setError(`Error fetching image: ${error}`)
      }

      setIsLoading(false)
      onImageLoad()
    }
    if (!imageSrc) {
      fetchImage()
    }
  }, [fileDetails, imageSrc, onImageLoad])

  return (
    <div>
      {isLoading ? (
        <div>Loading image ... </div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <img
          src={imageSrc}
          alt={fileDetails.name}
          style={{ maxWidth: '30%' }}
        />
      )}
    </div>
  )
}
export default ImageContainer
