import React from 'react'
import { Circles } from 'react-loader-spinner'
import styles from './../../styles/LoadingSpinner.module.css'
const LoadingSpinner = () => {
  return (
    <div className={styles.spinner}>
      <Circles
        height="80"
        width="80"
        color="rgb(31, 164, 201)"
        ariaLabel="loading"
      />
    </div>
  )
}

export default LoadingSpinner
