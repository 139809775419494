import { useState } from 'react'
import { useClickAway } from '@uidotdev/usehooks'
import { AnimatePresence, motion } from 'framer-motion'
import styles from '../../styles/SquashMenu.module.css'
import { useTheme } from '../../contexts/ThemeContext'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

const SquashMenu = ({ menuItems, chatbotId }) => {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useClickAway(() => {
    setIsOpen(false)
  })

  const handleItemClicked = (action, chatbotId) => {
    setIsOpen(false)
    action(chatbotId)
  }

  const { theme } = useTheme()
  const squashColor = theme === 'dark' ? '#ffffff' : '#2020209c'

  return (
    <div ref={ref}>
      <MoreHorizIcon
        onClick={() => setIsOpen(!isOpen)}
        style={{ color: squashColor }}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className={styles.menu}>
              {menuItems.map((item) => (
                <div
                  key={item.id}
                  onClick={() => handleItemClicked(item.action, chatbotId)}
                >
                  {item.icon}
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
export default SquashMenu
