// ProtectedRoute.js
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

const ProtectedRoutes = ({ roles }) => {
  const { user, isAuthorized, isLoading } = useAuth()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!isAuthorized()) {
    return <Navigate to="/login" />
  }

  if (roles && !roles.includes(user.role)) {
    return <Navigate to="/chatbot-dashboard" />
  }

  return <Outlet />
}

export default ProtectedRoutes

// import { useEffect, useState } from 'react'
// import { Navigate, Outlet } from 'react-router-dom'
// import { useAuth } from '../contexts/AuthContext'
// import axios from 'axios' // Assume axios is set up for API calls

// const ProtectedRoutes = ({ roles }) => {
//   const { user, setUser, isLoading, setIsLoading } = useAuth()
//   const [authChecked, setAuthChecked] = useState(false)

//   useEffect(() => {
//     const checkAuthStatus = async () => {
//       setIsLoading(true)
//       try {
//         const isAuthenticated = await verifyAuthStatus() // Implement this function
//         if (!isAuthenticated) {
//           setUser(null)
//         }
//         setAuthChecked(true)
//       } catch (error) {
//         console.error('Error verifying auth status:', error)
//         setUser(null)
//       } finally {
//         setIsLoading(false)
//       }
//     }

//     if (!user || !authChecked) {
//       checkAuthStatus()
//     }
//   }, [user, setUser, setIsLoading, authChecked])

//   if (isLoading || !authChecked) {
//     return <div>Loading...</div>
//   }

//   if (!user) {
//     return <Navigate to="/login" />
//   }

//   // Additional role checks here as before

//   return <Outlet />
// }
