import styles from '../../styles/SectionOne.module.css'
import GeneralButton from '../GeneralButton'
import { forwardRef } from 'react'

const SectionOne = forwardRef((props, ref) => {
  const videoSrc = `${process.env.PUBLIC_URL}/images/landingPage/section1/Chat Interface Dark Theme.mp4`

  return (
    <div ref={ref} id="section1" className={styles.Container}>
      <div className={styles.simple_radial}>
        <div className={styles.contentWrapper}>
          <div className={styles.text}>
            Empower Innovation & Learning in your organization: <br />
            Effortlessly design, customize, and share your chatbots with AiHub
          </div>
          <video
            className={styles.video}
            loop // This will make the video loop
            autoPlay // This will auto-play the video
            muted // This will mute the video
            playsInline // This can help with autoplay on some mobile devices
          >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className={styles.buttonContainer}>
            <GeneralButton text="Login" to="/chatbot-dashboard" />
          </div>
        </div>
      </div>
    </div>
  )
})

export default SectionOne
