import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-regular-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/DropZoneInput.module.css'

const DropZoneInput = ({ onFileAccepted }) => {
  const [previewUrl, setPreviewUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    maxSize: parseInt(3145728, 10),
    maxFiles: 1,
    multiple: false,
    onDropAccepted: (acceptedFiles) => {
      setIsLoading(true) // Set loading to true
      setError('') // Clear any previous errors
      const file = acceptedFiles[0]
      if (file) {
        // Generate a preview URL
        const url = URL.createObjectURL(file)
        setPreviewUrl(url)
        setIsLoading(false) // Set loading to false after setting the URL
        onFileAccepted(file) // Optionally handle the file in the parent component
      }
    },
    onDropRejected: () => {
      setError('File type not supported or file is too large.')
      setIsLoading(false)
    },
  })

  useEffect(() => {
    // Clean up the URL object
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl)
      }
    }
  }, [previewUrl])

  const handleRemoveFile = (event) => {
    event.stopPropagation() // Prevent the drop zone click event
    URL.revokeObjectURL(previewUrl) // Free up the blob URL
    setPreviewUrl(null) // Clear the preview URL state
    setError('') // Optionally clear any error messages
    setIsLoading(false) // Stop any loading indication
    onFileAccepted(null) // Notify the parent component that the file has been removed
  }

  return (
    <div
      {...getRootProps({
        className: `${styles.container} dropzone`,
      })}
    >
      <input {...getInputProps()} />

      {!previewUrl && (
        <>
          <p className={styles.text}>Drag & Drop image file, or Browse</p>
          <FontAwesomeIcon icon={faImage} className={styles.icon} />
          <p className={styles.text}>Supports: JEG, JPG, PNG</p>
        </>
      )}

      {isLoading && <p>Loading...</p>}
      {error && <p className={styles.text}>{error}</p>}
      {previewUrl && (
        <>
          <FontAwesomeIcon
            icon={faTrash}
            className={`${styles.icon} ${styles.floatingIcon}`}
            onClick={handleRemoveFile}
          />
          <img src={previewUrl} alt="Preview" className={styles.picture} />
        </>
      )}
    </div>
  )
}

export default DropZoneInput
