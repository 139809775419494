import styles from '../../styles/HomePage_.module.css'
import { motion } from 'framer-motion'
import { pageVariants, pageTransition } from '../../animations/pageTransitions'
import BottomNavBar from '../navigation/BotoomNavBar'
import SectionOne from '../HomePageSctions/SectionOne'
import SectionTwo from '../HomePageSctions/SectionTwo'
import SectionThree from '../HomePageSctions/SectionThree'
import SectionFour from '../HomePageSctions/SectionFour'
import SectionFive from '../HomePageSctions/SectionFive'
import SectionSix from '../HomePageSctions/SectionSix'
import { useRef, useEffect, useState, useMemo } from 'react'

const HomePage_ = () => {
  const backgroundImage = `url(${process.env.PUBLIC_URL}/images/linesTransparent.png)`

  const mainContentRef = useRef(null)

  const sectionOneRef = useRef(null)
  const sectionTwoRef = useRef(null)
  const sectionThreeRef = useRef(null)
  const sectionFiveRef = useRef(null)

  const sectionRefs = useMemo(
    () => ({
      section1: sectionOneRef,
      section2: sectionTwoRef,
      section3: sectionThreeRef,
      section5: sectionFiveRef,
    }),
    [sectionOneRef, sectionTwoRef, sectionThreeRef, sectionFiveRef],
  ) // Dependencies are the refs themselves

  const scrollTo = (sectionId) => {
    const ref = sectionRefs[sectionId]
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
      setCurrentSection(sectionId)
    }
  }

  const [currentSection, setCurrentSection] = useState('section1')

  useEffect(() => {
    const handleScroll = () => {
      // Ensure we have the main content element and it's not null
      const mainContent = mainContentRef.current
      if (!mainContent) return

      // Use mainContent.scrollTop plus some offset for the current scroll position
      const scrollPosition = mainContent.scrollTop + mainContent.offsetTop

      let currentKey = null
      Object.keys(sectionRefs).forEach((key) => {
        const section = sectionRefs[key].current
        if (section) {
          const sectionTop = section.offsetTop - mainContent.offsetTop // Relative to mainContent
          const sectionBottom = sectionTop + section.offsetHeight

          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            currentKey = key
          }
        }
      })

      if (currentKey) {
        setCurrentSection(currentKey)
      }
    }

    const mainContentElement = mainContentRef.current
    mainContentElement.addEventListener('scroll', handleScroll)
    return () => mainContentElement.removeEventListener('scroll', handleScroll)
  }, [mainContentRef, sectionRefs]) // Add sectionRefs to dependencies to ensure it updates with them

  // Helper function for throttling
  function throttle(func, limit) {
    let inThrottle
    return function () {
      const args = arguments
      const context = this
      if (!inThrottle) {
        func.apply(context, args)
        inThrottle = true
        setTimeout(() => (inThrottle = false), limit)
      }
    }
  }

  return (
    <motion.div
      style={{ width: '100%', height: '100%' }}
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      transition={pageTransition}
    >
      <div
        ref={mainContentRef}
        className={styles.MainContent}
        id="mainContentScroll"
        style={{ backgroundImage }}
      >
        <SectionOne ref={sectionOneRef} />
        <SectionTwo ref={sectionTwoRef} />
        <SectionThree ref={sectionThreeRef} />
        <SectionFive ref={sectionFiveRef} />
      </div>
      <BottomNavBar
        onSectionSelect={scrollTo}
        currentSection={currentSection}
        sections={{
          sectionOneRef,
          sectionTwoRef,
          sectionThreeRef,
          sectionFiveRef,
        }}
      />
    </motion.div>
  )
}

export default HomePage_
