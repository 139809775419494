import styles from '../../styles/SectionTwo.module.css'
import GeneralButton from '../GeneralButton'
import { forwardRef } from 'react'

const SectionTwo = forwardRef((props, ref) => {
  const videoSrc = `${process.env.PUBLIC_URL}/images/landingPage/section2/Chatlab Dark Theme.mp4`

  return (
    <div ref={ref} id="section2" className={styles.Container}>
      <div className={styles.simple_radial}>
        <div className={styles.contentWrapper}>
          <div className={styles.text}>
            How It Works: A Seamless Creation Process
          </div>
          <div className={styles.contentWrapper2}>
            <div className={styles.contentWrapper3}>
              <div className={styles.secondaryText}>
                Build, Deploy, <br />
                and Manage with Simplicity
              </div>
              <div className={styles.paragraph}>
                Create your AI chatbot in three easy steps with AiHub. Use
                ChatLab's no-code, drag-and-drop to build, customize with your
                data, and deploy organization-wide. Monitor performance and
                engagement effortlessly on our intuitive platform.
              </div>
            </div>
            <video
              className={styles.video}
              loop // This will make the video loop
              autoPlay // This will auto-play the video
              muted // This will mute the video
              playsInline // This can help with autoplay on some mobile devices
            >
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  )
})

export default SectionTwo
