import './Global.css'
import LoginPage from './components/pages/LoginPage'
import NavBar from './components/navigation/NavBar'
import { AuthProvider } from './contexts/AuthContext'
import AuthRedirector from './components/navigation/AuthRedirector'
import { ThemeProvider } from './contexts/ThemeContext'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import ProtectedRoutes from './utils/ProtectedRoutes'
import ChatbotDashboardPage from './components/pages/ChatbotDashboardPage'
import HomePage_ from './components/pages/HomePage_'
import MyProfilePage from './components/pages/MyProfilePage'
import CompanyManagementPage from './components/pages/CompanyMangementPage'
import UserManagementPage from './components/pages/UserManagementPage'
import ChatTokenTrackerPage from './components/pages/ChatTokenTrackerPage'
import ChatWindow from './components/chatting/ChatWindow'
import AddEditChatbotPage from './components/pages/AddEditChatbotPage'
import styles from './styles/App.module.css'
import ChatInterface from './components/chatting/ChatInterface'
import AddEditUser from './components/pages/AddEditUser'

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <AuthRedirector /> {/* Place the AuthRedirector here */}
          <NavBar />
          <div className={styles.container}>
            <Routes>
              {/* ALL PUBLIC ROUTES */}
              <Route
                path="/"
                element={
                  <AnimatePresence>
                    <HomePage_ />
                  </AnimatePresence>
                }
              />
              <Route path="/login" element={<LoginPage />} />

              {/* ALL  PROTECTED ROUTES FOR WEB ADMIN AND COMAPNY ADMIN*/}
              <Route
                element={
                  <ProtectedRoutes roles={['web_admin', 'company_admin']} />
                }
              >
                <Route
                  path="/register-chatbot"
                  element={<AddEditChatbotPage />}
                />
                <Route
                  path="/update-chatbot"
                  element={<AddEditChatbotPage />}
                />
              </Route>
              {/* ALL PROTECTED ROUTES FOR WEB ADMIN */}
              <Route element={<ProtectedRoutes roles={['web_admin']} />}>
                <Route
                  path="/company-management"
                  element={<CompanyManagementPage />}
                />
              </Route>

              {/* ALL PROTECTED ROUTES FOR COPMANY ADMIN */}
              <Route element={<ProtectedRoutes roles={['company_admin']} />}>
                <Route
                  path="/user-management"
                  element={<UserManagementPage />}
                />
                <Route
                  path="/chat-token-tracker"
                  element={<ChatTokenTrackerPage />}
                />
                <Route path="/update-user" element={<AddEditUser />} />
                <Route path="/add-user" element={<AddEditUser />} />
              </Route>

              {/* ALL PROTECTED ROUTES FOR COPMANY ADMIN AND USER */}
              <Route
                element={<ProtectedRoutes roles={['company_admin', 'user']} />}
              >
                <Route
                  path="/chatbot-dashboard"
                  element={<ChatbotDashboardPage />}
                />
                <Route
                  path="/chatbot-dashboard/:chatBotId"
                  element={<ChatInterface />}
                >
                  <Route index element={<ChatWindow />} />{' '}
                  {/* New chat window */}
                  <Route path=":conversationId" element={<ChatWindow />} />{' '}
                  {/* Specific conversation */}
                </Route>
              </Route>

              {/* ALL PROTECTED ROUTES FOR WEB ADMIN, COPMANY ADMIN AND USER */}
              <Route
                element={
                  <ProtectedRoutes
                    roles={['web_admin', 'company_admin', 'user']}
                  />
                }
              >
                <Route path="/my-profile" element={<MyProfilePage />} />
              </Route>
            </Routes>
          </div>
        </Router>
        {/*Add components here*/}
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
