import { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { login as apiLogin } from '../../services/API/AuthServices'
import styles from '../../styles/LoginPage.module.css'
import GeneralButton from '../GeneralButton'
import { pageVariants, pageTransition } from '../../animations/pageTransitions'
import { motion } from 'framer-motion'

const LoginPage = () => {
  const backgroundImage =
    'url(' + process.env.PUBLIC_URL + '/images/lines3.png)' //in the jsx section below in style you do not need to specify backgroundImage: backgroundImage, it is enough to use just backgroundImage
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const { login } = useAuth()
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!username || !password) {
      setError('Please fill in all required fields.')
      return
    }
    try {
      const response = await apiLogin(username, password)

      if (response.data.success) {
        // Successful login logic
        login(response.data)

        navigate('/chatbot-dashboard')
      } else {
        // If success is false but no status code is provided, use the message from response
        setError(
          response.data.message || 'An error occurred. Please try again.',
        )
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        // Handle incorrect credentials
        setError('Invalid login credentials. Please try again.')
      } else {
        // Handle other types of errors
        setError(err.message || 'An error occurred during login.')
      }
    }
  }

  return (
    <motion.div
      style={{ width: '100%', height: '100%' }}
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      transition={pageTransition}
    >
      <div className={styles.pageBackground}>
        <div className={styles.loginContainer}>
          <div className={styles.loginText}>Log in</div>
          {error && <p className={styles.errorMessage}>{error}</p>}
          <form className={styles.form} onSubmit={handleSubmit}>
            <input
              type="text"
              value={username}
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
            />

            <input
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <GeneralButton text="Log in" type="submit" />
          </form>
        </div>
      </div>
    </motion.div>
  )
}
export default LoginPage
