import styles from '../../styles/NavBar.module.css'
import React, { useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import HamburgerMenu from './HamburgerMenu'
import { Link } from 'react-router-dom'
import ThemeSwitcherButton from '../ThemeSwitcherButton'

const NavBar = () => {
  const { user, isAuthorized } = useAuth()
  const [hamburgerVisibility, sethamburgerVisibility] = useState(false)
  const logoPath = process.env.PUBLIC_URL + '/images/AiHubLogo.png'

  const handleHamburgerMenuVisibility = () => {
    sethamburgerVisibility(!hamburgerVisibility)
  }
  const getUserInitials = (user) => {
    if (user && user.name && user.surname) {
      return `${user.name.charAt(0)}${user.surname.charAt(0)}`
    }
    return ''
  }

  return (
    <nav className={styles.nav}>
      <Link to="/chatbot-dashboard" className={styles.siteTitle}>
        <img src={logoPath} className={styles.logo} alt="HA logo" />
      </Link>
      <div className={styles.rightContainer}>
        <ThemeSwitcherButton />
        {isAuthorized() ? (
          <div>
            <button
              className={styles.dropBtn}
              onClick={handleHamburgerMenuVisibility}
            >
              {getUserInitials(user)}
            </button>
            {hamburgerVisibility && (
              <HamburgerMenu
                initials={getUserInitials(user)}
                toggelVisibilityMenu={handleHamburgerMenuVisibility}
              />
            )}
          </div>
        ) : (
          <div>
            <Link className={styles.navButton} to="/login">
              Login
            </Link>
          </div>
        )}
      </div>
    </nav>
  )
}

export default NavBar
