export const pageVariants = {
  initial: {
    opacity: 0,
    // Remove the scale transformation to prevent layout shifts
  },
  in: {
    opacity: 1,
    // Ensure the content stays at its natural scale
  },
  out: {
    opacity: 0,
    // Maintain the content's scale on exit as well
  },
}

export const pageTransition = {
  type: 'tween',
  ease: 'easeInOut', // Smooth acceleration and deceleration
  duration: 0.5, // Duration of the transition
}
