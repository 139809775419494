import styles from '../../styles/BottomNavBar.module.css'

const BottomNavBar = ({ onSectionSelect, currentSection }) => {
  return (
    <div className={styles.BottomNavBar}>
      <div className={styles.BottomNavBarItems}>
        <div
          className={`${styles.BottomNavBarItem} ${
            currentSection === 'section1' ? styles.active : ''
          }`}
          onClick={() => onSectionSelect('section1')}
        >
          Home
        </div>
        <div
          className={`${styles.BottomNavBarItem} ${
            currentSection === 'section2' ? styles.active : ''
          }`}
          onClick={() => onSectionSelect('section2')}
        >
          How it works
        </div>
        <div
          className={`${styles.BottomNavBarItem} ${
            currentSection === 'section3' ? styles.active : ''
          }`}
          onClick={() => onSectionSelect('section3')}
        >
          Features & Benefits
        </div>
        <div
          className={`${styles.BottomNavBarItem} ${
            currentSection === 'section5' ? styles.active : ''
          }`}
          onClick={() => onSectionSelect('section5')}
        >
          Contact
        </div>
      </div>
    </div>
  )
}

export default BottomNavBar
