import api from './APIconfig'
import { storeAnimation, getAnimation } from './../../utils/animationIDLEidb'
import axios from 'axios'

const url = process.env.REACT_APP_DID_URL
const didKey = process.env.REACT_APP_DID_KEY

const ivonne = process.env.REACT_APP_IVONNE
const mary = process.env.REACT_APP_MARY
const robert = process.env.REACT_APP_ROBERT
const sam = process.env.REACT_APP_SAM
const sheila = process.env.REACT_APP_SHEILA
const sophie = process.env.REACT_APP_SOPHIE
const william = process.env.REACT_APP_WILLIAM

const urlPictures = [ivonne, mary, robert, sam, sheila, sophie, william]

const nameToIndexMap = {
  Ivonne: 0,
  Mary: 1,
  Robert: 2,
  Sam: 3,
  Sheila: 4,
  Sophie: 5,
  William: 6,
}

export const fetchIDLEvideo = async (name, withToken = false) => {
  const cachedAnimation = await getAnimation(name)
  if (cachedAnimation) {
    console.log('Cached animation')
    return { videoUrl: cachedAnimation }
  } else {
    console.log('No cached animation')
    try {
      const index = nameToIndexMap[name]
      if (index === undefined) {
        throw new Error(`Invalid name: ${name}`)
      }
      const response = await api.post(
        `${url}/talks`,
        {
          source_url: urlPictures[index],
          driver_url: 'bank://lively/driver-06',
          script: {
            type: 'text',
            ssml: true,
            input:
              '<break time="5000ms"/><break time="5000ms"/><break time="5000ms"/>',
            provider: {
              type: 'microsoft',
              voice_id: 'en-US-JennyNeural',
            },
          },
          config: {
            stitch: true,
            fluent: true,
            align_driver: true, // Ensure driver alignment
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Basic ${didKey}`,
            withToken: withToken,
          },
        },
      )
      console.log('Data: ', response)
      const talkId = response.data.id
      console.log('The response is: ', response)
      if (talkId) {
        // Poll for the status of the talk
        let talkStatus = 'created'

        let talkResponse
        const startTime = Date.now()
        const maxDuration = 60000 // 60 seconds

        console.log('TALK STATUS IS: ', talkStatus)

        while (talkStatus !== 'done') {
          console.log('talk status not done idle')
          if (Date.now() - startTime > maxDuration) {
            return { error: 'Polling timeout exceeded' }
          }

          talkResponse = await api.get(`${url}/talks/${talkId}`, {
            headers: {
              Authorization: `Basic ${didKey}`,
              withToken: withToken, // Replace with your actual API key
            },
          })

          talkStatus = talkResponse.data.status

          if (talkStatus !== 'done') {
            await new Promise((resolve) => setTimeout(resolve, 5000)) // Wait for 5 seconds before polling again
          }
        }

        console.log('RESPONSE FROM DID TALK: ', response)
        console.log('RESPONSE FROM DID TALK res: ', talkResponse.data)

        const resultUrl = talkResponse.data.result_url
        await storeAnimation(name, resultUrl)

        return { result: talkResponse.data }
      }
    } catch (error) {
      let errorMessage = 'An unknown error occurred'

      if (error.response) {
        switch (error.response.status) {
          case 400:
            errorMessage = `BadRequestError: ${error.response.data.description}`
            break
          case 401:
            errorMessage = `AuthorizationError: ${error.response.data.description}`
            break
          case 402:
            errorMessage = `InsufficientCreditsError: ${error.response.data.description}`
            break
          case 403:
            errorMessage = `PermissionError: ${error.response.data.description}`
            break
          case 451:
            errorMessage = `ModerationError: ${error.response.data.description}`
            break
          default:
            errorMessage = `Unknown error: ${error.response.data}`
        }
      } else {
        errorMessage = `Error: ${error.message}`
      }

      console.error(errorMessage)
      return { error: errorMessage }
    }
  }
}

export const fetchDIDtalk = async (name, text, withToken = false) => {
  let voiceID
  if (
    name === 'Sophie' ||
    name === 'Sheila' ||
    name === 'Ivonne' ||
    name === 'Mary'
  ) {
    console.log('Female voice')
    voiceID = 'en-US-JennyNeural'
  } else {
    console.log('Male voice')
    voiceID = 'en-US-AndrewMultilingualNeural'
  }
  try {
    const index = nameToIndexMap[name]
    if (index === undefined) {
      throw new Error(`Invalid name: ${name}`)
    }

    // Create a talk
    const response = await api.post(
      `${url}/talks`,
      {
        source_url: urlPictures[index],
        script: {
          type: 'text',
          input: text,
          provider: { type: 'microsoft', voice_id: voiceID },
        },
        config: {
          stitch: true,
          fluent: true,
          align_driver: true, // Ensure driver alignment
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${didKey}`,
          withToken: withToken, // Replace with your actual API key
        },
      },
    )

    const talkId = response.data.id
    console.log('TALK ID IS: ', talkId)

    // Poll for the status of the talk
    let talkStatus = 'created'
    let talkResponse
    const startTime = Date.now()
    const maxDuration = 60000 // 60 seconds

    while (talkStatus !== 'done') {
      if (Date.now() - startTime > maxDuration) {
        return { error: 'Polling timeout exceeded' }
      }

      talkResponse = await api.get(`${url}/talks/${talkId}`, {
        headers: {
          Authorization: `Basic ${didKey}`,
          withToken: withToken, // Replace with your actual API key
        },
      })

      talkStatus = talkResponse.data.status

      if (talkStatus !== 'done') {
        await new Promise((resolve) => setTimeout(resolve, 5000)) // Wait for 5 seconds before polling again
      }
    }

    console.log('RESPONSE FROM DID TALK: ', response)
    console.log('RESPONSE FROM DID TALK res: ', talkResponse.data)
    return { result: talkResponse.data }
  } catch (error) {
    let errorMessage = 'An unknown error occurred'

    if (error.response) {
      switch (error.response.status) {
        case 400:
          errorMessage = `BadRequestError: ${error.response.data.description}`
          break
        case 401:
          errorMessage = `AuthorizationError: ${error.response.data.description}`
          break
        case 402:
          errorMessage = `InsufficientCreditsError: ${error.response.data.description}`
          break
        case 403:
          errorMessage = `PermissionError: ${error.response.data.description}`
          break
        case 451:
          errorMessage = `ModerationError: ${error.response.data.description}`
          break
        default:
          errorMessage = `Unknown error: ${error.response.data}`
      }
    } else {
      errorMessage = `Error: ${error.message}`
    }

    console.error(errorMessage)
    return { error: errorMessage }
  }
}

export const fetchDIDtalk2 = async (name, text, withToken = false) => {
  try {
    const index = nameToIndexMap[name]
    if (index === undefined) {
      throw new Error(`Invalid name: ${name}`)
    }
    // Create a talk
    const response = await axios.post(
      `${url}/talks`,
      {
        source_url: urlPictures[index],
        script: {
          type: 'text',
          input: text,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${didKey}`, // Replace with your actual API key
        },
      },
    )

    const talkId = response.data.id

    // Poll for the status of the talk
    let talkStatus = 'created'
    let talkResponse

    while (talkStatus !== 'done') {
      talkResponse = await axios.get(`${url}/talks/${talkId}`, {
        headers: {
          Authorization: `Basic ${didKey}`, // Replace with your actual API key
        },
      })

      talkStatus = talkResponse.data.status

      if (talkStatus !== 'done') {
        await new Promise((resolve) => setTimeout(resolve, 5000)) // Wait for 5 seconds before polling again
      }
    }

    console.log('RESPONSE FROM DID TALK: ', talkResponse)
    return talkResponse.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
