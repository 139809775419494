import styles from '../../styles/ConversationListPanel.module.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import EditNoteIcon from '@mui/icons-material/EditNote'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteIcon from '@mui/icons-material/Delete'
import { deleteChat } from '../../services/API/ChatServices'
import ErrorCard from '../ErrorCard'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getUserConversations } from '../../services/API/ChatServices'

const ConversationListPanel = ({
  className,
  conversationList,
  chatMessagesSaved,
  setPanelVisibility,
}) => {
  const [conversations, setConversations] = useState(conversationList)

  // useEffect(() => {
  //   setConversations(conversationList)
  // }, [conversationList])

  const location = useLocation()

  let { chatBotId } = useParams()

  //conversationList.map((conv) => console.log('Is is: ', conv.conversation_id))

  // Option to use chatBotId from session storage
  //const chatbotData = getChatbotDataFromSession()
  //const chatBotId = chatbotDataSession ? chatbotDataSession.chatbotID : null

  useEffect(() => {
    const fetchUserConversations = async () => {
      try {
        if (chatBotId) {
          const response = await getUserConversations(chatBotId)
          if (response.success) {
            setConversations(response.payload.reverse())
          } else {
            //setInformation(response.message)
            //console.log(response.message)
          }
        }
      } catch (error) {
        setError('An unexpected error occurred. Please try again.')
      }
    }

    fetchUserConversations()
  }, [chatBotId, chatMessagesSaved])

  // Check if the URL is exactly for the new chat
  const isNewChatActive =
    location.pathname === `/chatbot-dashboard/${chatBotId}`

  const getLinkClassName = (conversationId) => {
    // Check if the current conversationId is in the URL
    const isActive = location.pathname.endsWith(
      `/${chatBotId}/${conversationId}`,
    )
    return isActive ? `${styles.link} ${styles.activeLink}` : styles.link
  }

  const [error, setError] = useState(false)

  const navigate = useNavigate()

  const handleLinkClick = (link) => {
    if (
      !chatMessagesSaved &&
      !window.confirm('You have unsaved changes. Continue?')
    ) {
      return
    }
    setPanelVisibility()
    navigate(link)
  }
  const handleDelete = async (conversationId) => {
    try {
      const response = await deleteChat(conversationId)
      if (
        response.success &&
        response.message === 'Chat deleted successfully'
      ) {
        //chat deleted successfully
        if (location.pathname.endsWith(`/${chatBotId}/${conversationId}`)) {
          //this means that delted conv is the one which is currently open
          //we need to redirect user to new chat after deleting
          setConversations((prevConversations) =>
            prevConversations.filter(
              (conv) => conv.conversation_id !== conversationId,
            ),
          )
          navigate(`/chatbot-dashboard/${chatBotId}`)
        } else {
          //it is different saved chat or it is a new chat
          //just to delete from the converstionlist

          setConversations((prevConversations) =>
            prevConversations.filter(
              (conv) => conv.conversation_id !== conversationId,
            ),
          )
        }
      } else {
        //problem
        setError(true)
      }
    } catch (error) {
      setError(true)
    }
  }

  return (
    <div className={`${styles.panel} ${className}`}>
      {/* Link to start a new chat */}
      <div className={`${styles.conversation_item} `}>
        <Link
          className={
            isNewChatActive
              ? `${styles.link} ${styles.activeLink} `
              : `${styles.link} `
          }
          to={`/chatbot-dashboard/${chatBotId}`}
          onClick={(e) => {
            e.preventDefault()
            handleLinkClick(`/chatbot-dashboard/${chatBotId}`)
          }}
        >
          <div className={styles.textContainer}>New Chat</div>
        </Link>
        <EditNoteIcon className={styles.icon} />
      </div>
      <hr className={styles.line} />
      <div className={styles.conversationsList}>
        {conversations.map((conv) => (
          <div
            key={conv.conversation_id}
            className={`${styles.conversation_item} `}
          >
            <Link
              key={conv.conversation_id}
              onClick={(e) => {
                e.preventDefault()
                handleLinkClick(
                  `/chatbot-dashboard/${chatBotId}/${conv.conversation_id}`,
                )
              }}
              className={`${styles.conversationTitle} ${getLinkClassName(
                conv.conversation_id,
              )}`}
            >
              <div className={styles.textContainer}>{conv.title}</div>
            </Link>
            <div
              className={`${styles.delete_button} ${styles.icon}`}
              onClick={() => handleDelete(conv.conversation_id)}
            >
              <DeleteIcon />
            </div>
          </div>
        ))}
        <Link
          className={styles.arrowBack}
          to="/chatbot-dashboard"
          onClick={(e) => {
            e.preventDefault()
            handleLinkClick(`/chatbot-dashboard`)
          }}
        >
          <ArrowBackIcon />
        </Link>
      </div>
      {error && (
        <ErrorCard
          message="An unexpected error occurred while trying to delete your conversation"
          name="OK"
          action="CONFIRM"
          handleBtnClick={() => setError(false)}
        />
      )}
    </div>
  )
}

export default ConversationListPanel
