import React from 'react'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from './../../styles/VideoSwitch.module.css'

import { styled } from '@mui/material/styles'

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  verticalAlign: 'middle',
  WebkitTapHighlightColor: 'transparent',
  marginLeft: 90, // Set to 0
  marginRight: 0, // Set to 0
  '& .MuiFormControlLabel-label': {
    fontSize: '0.9rem', // Customize the font size
    color: 'rgb(31, 164, 201)', // Customize the color
  },
}))

function VideoSwitch({ checked, onChange }) {
  return (
    <CustomFormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          name="muiSwitch"
          color="primary"
          size="small"
        />
      }
      label={checked ? 'Video Stream On' : 'Video Stream Off'}
    />
  )
}

export default VideoSwitch
