import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext' // Adjust the path as necessary

const AuthRedirector = () => {
  const { isAuthorized } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const hasBeenRedirected = sessionStorage.getItem('hasBeenRedirected')
    const shouldRedirect = isAuthorized() && !hasBeenRedirected

    if (shouldRedirect) {
      sessionStorage.setItem('hasBeenRedirected', 'true')
      navigate('/chatbot-dashboard')
    }
  }, [isAuthorized, navigate])

  return null // This component does not render anything
}
export default AuthRedirector
