import styles from '../../styles/ChatbotsInformation.module.css'
import Container from '../Container'

const ChatbotsInformation = ({ data, message }) => {
  const isDataAvailable = data && data.length > 0

  const filteredData = isDataAvailable
    ? data.map((chatbot) => ({
        name: chatbot.name,
        title: chatbot.title,
        avatar: chatbot.avatar,
      }))
    : []

  return (
    <Container
      text="Active Chatbots"
      description="This section displays a visual roster of all the active chatbots currently operational within your company."
    >
      <div className={styles.dataContainer}>
        {isDataAvailable ? (
          filteredData.map((chatbot, index) => (
            <div key={index} className={styles.chatbotCard}>
              <img
                src={`${process.env.PUBLIC_URL}/images/avatars/${chatbot.avatar}.png`}
                alt={chatbot.name}
                className={styles.chatbotAvatar}
              />
              <div className={styles.chatbotName}>{chatbot.name}</div>
              <div className={styles.chatbotTitle}>{chatbot.title}</div>
            </div>
          ))
        ) : (
          <p className={styles.infoText}>{message}</p>
        )}
      </div>
    </Container>
  )
}
export default ChatbotsInformation
