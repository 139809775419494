import styles from '../styles/Container.module.css'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'

const Container = ({ text, description, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        {text}
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={description}
          arrow
          placement="right"
        >
          <Button>Info</Button>
        </Tooltip>
      </div>
      {children}
    </div>
  )
}
export default Container
