import styles from '../../styles/UserManagement.module.css'
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { getUsersData, deleteUser } from '../../services/API/UsersServices'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import EditNoteIcon from '@mui/icons-material/EditNote'
import DeleteIcon from '@mui/icons-material/Delete'
import InformationCard from '../InformationCard'
import UserCard from '../UserCard'
import { useAuth } from '../../contexts/AuthContext'
import { pageVariants, pageTransition } from '../../animations/pageTransitions'
import { motion } from 'framer-motion'

const UserManagementPage = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [usersData, setUsersData] = useState([])
  const [usersDataInfo, setUsersDataInfo] = useState('')

  const [expandedUserId, setExpandedUserId] = useState(null)
  const expandedRef = useRef(null) // Create a ref for the expanded element

  const [showErrorDelete, setShowErrorDelete] = useState(false)

  const toggleExpand = (userId) => {
    setExpandedUserId(expandedUserId === userId ? null : userId)
  }

  useEffect(() => {
    if (expandedUserId !== null && expandedRef.current) {
      expandedRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [expandedUserId])

  useEffect(() => {
    const getUserInformation = async () => {
      try {
        const data = await getUsersData()
        if (data.success) {
          if (data.payload && data.payload.length > 0) {
            const filteredUsers = data.payload.filter(
              (userReceived) => userReceived.username !== user.username,
            )
            setUsersData(filteredUsers)
          } else {
            setUsersDataInfo('No users found.')
          }
        } else {
          setUsersDataInfo('No users found.')
        }
      } catch (error) {
        setUsersDataInfo('Error fetching users data')
      }
    }

    getUserInformation()
  }, [])

  const handleAddUserBtn = () => {
    navigate('/add-user')
  }

  const handleEditUser = (userId, event) => {
    event.stopPropagation()
    const user = usersData.filter((user) => user.user_id === userId)
    navigate('/update-user', { state: { user } })
  }

  const handleDeleteUser = async (userId, event) => {
    event.stopPropagation()
    try {
      const response = await deleteUser(userId)
      if (response.success) {
        // Update the state to remove the deleted user
        setUsersData(usersData.filter((user) => user.user_id !== userId))
      } else {
        setShowErrorDelete(true)
      }
    } catch (error) {
      console.error(`Error deleting user with ID: ${userId}`, error)
    }
  }

  const handleErrorDeleteVisibility = () => {
    setShowErrorDelete(false)
  }

  return (
    <motion.div
      style={{ width: '100%', height: '100%' }}
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      transition={pageTransition}
    >
      <div className={styles.container}>
        <div className={styles.pageTitle}>User Management</div>
        <div className={styles.titleLine}></div>
        <div>
          <div className={styles.container2}>
            <div className={styles.text}>
              Users
              <PersonAddIcon
                onClick={handleAddUserBtn}
                className={styles.addUserButton}
              />
            </div>
            <div className={styles.dataContainer}>
              {usersData.length > 0 ? (
                <ol className={styles.list}>
                  {usersData.map((user) => (
                    <li
                      key={user.user_id}
                      className={styles.details}
                      onClick={() => toggleExpand(user.user_id)}
                    >
                      <div className={styles.liContainer}>
                        <div>
                          {user.name} {user.surname}
                        </div>
                        <ExpandMoreIcon className={styles.expandButton} />
                      </div>

                      {expandedUserId === user.user_id && (
                        <div ref={expandedRef} className={styles.user}>
                          <div>
                            <div className={styles.iconsContainer}>
                              <EditNoteIcon
                                className={styles.icon}
                                onClick={(e) => handleEditUser(user.user_id, e)}
                              />
                              <DeleteIcon
                                className={styles.icon}
                                onClick={(e) =>
                                  handleDeleteUser(user.user_id, e)
                                }
                              />
                            </div>
                            <UserCard user={user} />
                          </div>
                        </div>
                      )}
                    </li>
                  ))}
                </ol>
              ) : (
                <p className={styles.infoText}>{usersDataInfo}</p>
              )}
            </div>
          </div>
        </div>
        {showErrorDelete && (
          <InformationCard
            title="Notice"
            information="There was a problem to delete user."
            toggleInformationVisibility={handleErrorDeleteVisibility}
          />
        )}
      </div>
    </motion.div>
  )
}
export default UserManagementPage
