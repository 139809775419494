import styles from '../styles/InformationCard.module.css'
import GeneralButton from './GeneralButton'
import { motion } from 'framer-motion'
import { pageVariants, pageTransition } from '../animations/pageTransitions'

const InformationCard = ({
  title,
  information,
  toggleInformationVisibility,
  imagePath,
}) => {
  return (
    <div className={styles.backdrop}>
      <div className={styles.card}>
        <h2>{title}</h2>
        <p>{information}</p>
        <GeneralButton text="OK" action={toggleInformationVisibility} />
      </div>
    </div>
  )
}

export default InformationCard

{
  /* <div
          className={styles.chatbotImage}
          style={{ backgroundImage: `url(${imagePath})` }}
        /> */
}
