import { useNavigate } from 'react-router-dom'
import styles from '../styles/GeneralButton.module.css'
export const GeneralButton = ({ text, type = 'button', action, to }) => {
  const navigate = useNavigate()
  const handleClick = (e) => {
    if (to) {
      navigate(to)
    } else if (action) {
      action(e)
    }
  }
  return (
    <button className={styles.button} type={type} onClick={handleClick}>
      {text}
    </button>
  )
}
export default GeneralButton
