import styles from '../../styles/AddEdituser.module.css'
import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  getAllCompanyChatbots,
  addNewUser,
  updateUser,
} from '../../services/API/UsersServices'
import GeneralButton from '../GeneralButton'
import { pageVariants, pageTransition } from '../../animations/pageTransitions'
import { motion } from 'framer-motion'
const AddEditUser = () => {
  const backgroundImage = `url(${process.env.PUBLIC_URL}/images/linesTransparent.png)`

  const location = useLocation()
  const userData = location.state?.user[0]
  console.log('USER DATA TO EDIT IS: ', userData)
  const isEditMode = Boolean(userData)
  const navigate = useNavigate()

  const [submitError, setSubmitError] = useState('')
  const [generalError, setGeneralError] = useState('')

  const [companyChatbots, setCompanyChatbots] = useState([])
  const [companyChatbotsInfo, setCompanyChatbotsInfo] = useState('')

  const [formState, setFormState] = useState({
    name: '',
    surname: '',
    username: '',
    email: '',
    password: '',
    confirm_password: '',
    role: 'user',
    chatbots: [],
  })

  const [passwordsMatch, setPasswordsMatch] = useState(true)

  useEffect(() => {
    // Check if passwords match whenever formState is updated
    if (!isEditMode) {
      // Check if both password fields are non-empty before comparing
      if (formState.password && formState.confirm_password) {
        setPasswordsMatch(formState.password === formState.confirm_password)
      } else {
        // If either field is empty, don't set the error state (keep passwordsMatch true)
        setPasswordsMatch(true)
      }
    }
  }, [formState.password, formState.confirm_password, isEditMode])

  useEffect(() => {
    if (isEditMode) {
      setFormState({
        user_id: userData.user_id,
        name: userData.name,
        surname: userData.surname,
        username: userData.username,
        email: userData.email,
        role: userData.role,
        chatbots: userData.chatbots.map((chatbot) => chatbot.chatbot_id),
      })
    }
    console.log('User id to edit is: ', formState.user_id)
  }, [isEditMode])

  useEffect(() => {
    //get available chatbots from the company
    const getCompanyChatbots = async () => {
      try {
        const data = await getAllCompanyChatbots()
        if (data.success && data.payload && data.payload.length > 0) {
          setCompanyChatbots(data.payload)
          setCompanyChatbotsInfo('')
        } else {
          setCompanyChatbotsInfo(
            'No chatbots available. Add chatbots first to be able to add a new user',
          )
        }
      } catch (error) {
        setCompanyChatbotsInfo(
          'Error fetching company chatbots, please try again later.',
        )
      }
    }
    getCompanyChatbots()
  }, [])

  const handleChange = (fieldName) => (e) => {
    const inputValue = e.target.value
    const limit = charLimits[fieldName]
    if (!limit || inputValue.length <= limit) {
      setFormState((prevState) => ({ ...prevState, [fieldName]: inputValue }))
    }
  }

  const charLimits = {
    name: 20, // Example limit for chatbot name
    surname: 20, // Example limit for expertise area
    username: 20, // Example limit for chatbot description
    email: 50, // Example limit for URL link
    password: 30,
  }

  const handleCheckboxChange = (chatbotId) => {
    setFormState((prevState) => {
      // Check if the chatbot ID is already in the array
      const isAlreadySelected = prevState.chatbots.includes(chatbotId)

      if (isAlreadySelected) {
        // If already selected, filter it out (remove)
        return {
          ...prevState,
          chatbots: prevState.chatbots.filter((id) => id !== chatbotId),
        }
      } else {
        // Otherwise, add the chatbot ID to the array
        return {
          ...prevState,
          chatbots: [...prevState.chatbots, chatbotId],
        }
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!isEditMode) {
      if (
        !formState.name ||
        !formState.surname ||
        !formState.username ||
        !formState.email ||
        !formState.password ||
        !formState.confirm_password ||
        formState.chatbots.length < 1
      ) {
        setGeneralError(
          'Please fill in all fields and select at least one chatbot.',
        )

        return
      } else {
        setGeneralError('')
      }
      try {
        const response = await addNewUser(formState)
        if (response.success) {
          navigate('/user-management')
        }
      } catch (err) {
        // Handle  errors
        setSubmitError(
          err.message || 'An error occurred while adding new user.',
        )
      }
    } else {
      console.log('User id to edit is: ', formState.user_id)
      if (
        !formState.name ||
        !formState.surname ||
        !formState.username ||
        !formState.email ||
        formState.chatbots.length < 1
      ) {
        setGeneralError(
          'Please fill in all fields and select at least one chatbot.',
        )

        return
      }
      try {
        const response = await updateUser(formState)
        navigate('/user-management')
      } catch (err) {
        // Handle  errors
        setSubmitError(err.message || 'An error occurred while updating user.')
      }
    }
  }

  useEffect(() => {
    if (submitError || generalError) {
      // Delay the scroll slightly
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100)
    }
  }, [submitError, generalError])

  return (
    <motion.div
      style={{ width: '100%', height: '100%' }}
      variants={pageVariants}
      initial="initial"
      animate="in"
      exit="out"
      transition={pageTransition}
    >
      <div className={styles.pageBackground} style={{ backgroundImage }}>
        <div className={styles.container}>
          <div className={styles.text}>
            {isEditMode ? `Update ${userData.name} user` : 'Add new user'}
          </div>
          {companyChatbotsInfo !== '' ? (
            <>
              <p className={styles.errorMessage}>{companyChatbotsInfo}</p>
              {/* Redirect button to chatbot registration page */}
              <button
                type="button"
                onClick={() => navigate('/register-chatbot')}
                className={styles.registerChatbotButton}
              >
                Register Chatbot
              </button>
            </>
          ) : (
            <>
              {submitError && (
                <p className={styles.errorMessage}>{submitError}</p>
              )}
              {generalError && (
                <p className={styles.errorMessage}>{generalError}</p>
              )}
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  value={formState.name}
                  placeholder="Name"
                  onChange={handleChange('name')}
                  maxLength={charLimits.name}
                />
                <input
                  type="text"
                  value={formState.surname}
                  placeholder="Surname"
                  onChange={handleChange('surname')}
                  maxLength={charLimits.surname}
                />
                <input
                  type="text"
                  value={formState.username}
                  placeholder="Username"
                  onChange={handleChange('username')}
                  maxLength={charLimits.username}
                />
                <input
                  type="email"
                  value={formState.email}
                  placeholder="Email"
                  onChange={handleChange('email')}
                  maxLength={charLimits.email}
                />
                {!isEditMode ? (
                  <input
                    type="password"
                    placeholder="Password"
                    onChange={handleChange('password')}
                    maxLength={charLimits.password}
                  />
                ) : (
                  ''
                )}
                {!isEditMode ? (
                  <input
                    type="password"
                    placeholder="Confirm password"
                    onChange={handleChange('confirm_password')}
                    maxLength={charLimits.password}
                  />
                ) : (
                  ''
                )}
                {!passwordsMatch && (
                  <p className={styles.errorMessage}>Passwords do not match</p>
                )}
                <label className={styles.label} htmlFor="role-select">
                  Choose a role:
                </label>
                <select
                  className={styles.selectText}
                  id="role-select"
                  onChange={handleChange('role')}
                  value={formState.role}
                >
                  <option value="user">User</option>
                  <option value="company_admin">Company Admin</option>
                </select>
                {companyChatbotsInfo !== '' ? (
                  <p className={styles.errorMessage}>{companyChatbotsInfo}</p>
                ) : (
                  <>
                    <div className={styles.label}>Assign chatbots to user:</div>
                    <div className={styles.chatbotsContainer}>
                      {companyChatbots.map((chatbot) => (
                        <div
                          className={styles.chatbotItem}
                          key={chatbot.chatbot_id}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/images/avatars/${chatbot.avatar}.png`}
                            alt={chatbot.name}
                            className={styles.chatbotAvatar}
                          />
                          <div className={styles.chatbotName}>
                            {chatbot.name}
                          </div>
                          <div className={styles.chatbotTitle}>
                            {chatbot.title}
                          </div>
                          <input
                            type="checkbox"
                            name="chatbotSelection"
                            value={chatbot.chatbot_id}
                            className={styles.chatbotCheckbox}
                            onChange={() =>
                              handleCheckboxChange(chatbot.chatbot_id)
                            }
                            checked={formState.chatbots.includes(
                              chatbot.chatbot_id,
                            )}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                )}
                <GeneralButton
                  type="submit"
                  text={isEditMode ? 'Update' : 'Add'}
                />
              </form>
            </>
          )}
        </div>
      </div>
    </motion.div>
  )
}
export default AddEditUser
