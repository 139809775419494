import React, { useMemo } from 'react'
import styles from '../../styles/ChatbotsTokensUsagePieChart.module.css'
import { PieChart } from '@mui/x-charts/PieChart'
import Container from '../Container'

const ChatbotsTokensUsagePieChart = ({ data, message }) => {
  const isDataAvailable = data && data.length > 0

  const { chartDataTotal, chartDataIn, chartDataOut } = useMemo(() => {
    const transformData = (key) =>
      // Ensure data is available and is an array before mapping
      isDataAvailable
        ? data.map((chatbot) => ({
            label: chatbot.name || 'Unnamed Chatbot',
            value: parseInt(chatbot[key], 10) || 0, // Always specify the radix for parseInt
          }))
        : [] // Return an empty array if data is not available

    return {
      chartDataTotal: transformData('grand_total'),
      chartDataIn: transformData('total_in'),
      chartDataOut: transformData('total_out'),
    }
  }, [data, isDataAvailable])

  return (
    <Container
      text="Chatbots Token Usage"
      description="Explore the interactive pie charts to gain insights into your chatbots' token activities. The 'Total' chart aggregates the overall token count across all active chatbots, reflecting your network's reach. 'In' tokens illustrate the engagement levels, showing how frequently users initiate conversations. 'Out' tokens track your chatbots' proactive communications, shedding light on their contribution to user interactions."
    >
      <div className={styles.dataContainer}>
        {isDataAvailable && message === '' ? (
          ['Total', 'In', 'Out'].map((type, index) => (
            <div key={index} className={styles.chartContainer}>
              <div className={styles.chartTitle}>
                Chatbots {type} Token Usage
              </div>
              <div className={styles.chart}>
                <PieChart
                  series={[
                    {
                      data:
                        type === 'Total'
                          ? chartDataTotal
                          : type === 'In'
                          ? chartDataIn
                          : chartDataOut,
                      innerRadius: 60,
                      outerRadius: 130,
                      paddingAngle: 5,
                      cornerRadius: 5,
                      startAngle: -90,
                      endAngle: 360,
                      cx: 150,
                      cy: 130,
                      highlightScope: {
                        faded: 'global',
                        highlighted: 'item',
                      },
                      faded: {
                        innerRadius: 30,
                        additionalRadius: -30,
                        color: 'gray',
                      },
                    },
                  ]}
                  slotProps={{
                    legend: {
                      hidden: true,
                      direction: 'column',
                      position: { vertical: 'middle', horizontal: 'right' },
                      padding: 0,
                      labelStyle: {
                        fontSize: 19,
                        fill: 'black',
                      },
                    },
                  }}
                />
              </div>
            </div>
          ))
        ) : (
          <p className={styles.infoText}>{message}</p>
        )}
      </div>
    </Container>
  )
}

export default ChatbotsTokensUsagePieChart
