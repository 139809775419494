import styles from '../styles/SendButton.module.css'

const SendButton = ({ onClick, disabled }) => {
  const imagePath = process.env.PUBLIC_URL + '/images/sendArrow.png'

  const handleClick = () => {
    if (!disabled) {
      onClick()
    }
  }

  const buttonStyles = disabled
    ? `${styles.send} ${styles.disabled}`
    : styles.send

  return (
    <div className={buttonStyles} onClick={handleClick}>
      <img className={styles.img} alt="Send" src={imagePath} />
    </div>
  )
}
export default SendButton
