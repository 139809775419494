import { useEffect, useState } from 'react'
import { useVoiceVisualizer, VoiceVisualizer } from 'react-voice-visualizer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMicrophone,
  faStop,
  faPlay,
  faPause,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import styles from './../../styles/VoiceRecorder.module.css'

const App = ({ onStartRecording, isRecording, getAudio }) => {
  // Initialize the recorder controls using the hook
  const recorderControls = useVoiceVisualizer()
  const {
    // ... (Extracted controls and states, if necessary)
    startRecording,
    stopRecording,
    recordedBlob,
    isRecordingInProgress,
    error,
    audioRef,
  } = recorderControls

  const [timer, setTimer] = useState(0)
  const [isTimerActive, setIsTimerActive] = useState(false)

  // Get the recorded audio blob
  useEffect(() => {
    if (!recordedBlob) return
    getAudio(recordedBlob)
    console.log('USE EFFECT RECORDED BLOB')
    console.log(recordedBlob)
  }, [recordedBlob, error])

  // Get the error when it occurs
  useEffect(() => {
    if (!error) return

    console.error(error)
  }, [error])

  useEffect(() => {
    if (!isRecording) {
      stopRecording()
      setIsTimerActive(false)
      setTimer(0)
    }
  }, [isRecording])

  const formatTime = (sec) => {
    const minutes = Math.floor(sec / 60)
    const seconds = sec % 60
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  useEffect(() => {
    let interval = null

    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1)
      }, 1000)
    } else if (!isTimerActive && timer !== 0) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)
  }, [isTimerActive, timer])

  const handleStartRecording = () => {
    startRecording()
    setIsTimerActive(true)
    onStartRecording()
  }

  return (
    <div>
      {!isRecordingInProgress ? (
        <FontAwesomeIcon
          icon={faMicrophone}
          className={styles.microphone}
          onClick={handleStartRecording}
        />
      ) : (
        <p className={styles.timer}>{formatTime(timer)}</p>
      )}
    </div>
  )
}

export default App
