import styles from '.././styles/CarouselCard.module.css' // Ensure you create this CSS module file

const CarouselCard = ({ item, location, animationDirection }) => {
  let animationClass = ''
  if (animationDirection === 'left') {
    animationClass = 'slideInFromLeft'
  } else if (animationDirection === 'right') {
    animationClass = 'slideInFromRight'
  }

  // Construct the cardClass string with animationClass included
  const cardClass = `${styles.card} ${
    location === 'active' ? styles.active : styles.small
  } ${styles[animationClass]}`

  const imageClass = `${
    location === 'side' ? styles.cardImageSmall : styles.cardImage
  }`

  const titleClass = `${
    location === 'side' ? styles.cardTitleSmall : styles.cardTitle
  }`

  const textClass = `${
    location === 'side' ? styles.cardTextSmall : styles.cardText
  }`

  return (
    // Conditionally render the card based on the visibility props

    <div className={cardClass}>
      <img src={item.picture} alt={item.title} className={imageClass} />
      <div className={titleClass}>{item.title}</div>
      <ul className={textClass}>
        {item.text.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>
    </div>
  )
}

export default CarouselCard
