import { createContext, useContext, useState, useEffect } from 'react'
import { jwtDecode } from 'jwt-decode'

const AuthContext = createContext()

const TOKEN_KEY = process.env.REACT_APP_TOKEN_KEY
const RABBIT_KEY = process.env.REACT_APP_RABBIT

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isAuthenticated, setisAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY)
    if (token && token !== 'undefined' && token !== '') {
      const decodedToken = jwtDecode(token)
      setUser({
        role: decodedToken.role,
        name: decodedToken.name,
        surname: decodedToken.surname,
        email: decodedToken.email,
        username: decodedToken.username,
        company_id: decodedToken.company_id,
      })
      setisAuthenticated(true)
    }
    setIsLoading(false)
  }, [])

  const login = (data) => {
    localStorage.setItem(RABBIT_KEY, data.encryptedUserId) // Store encryptedUserId
    const decodedToken = jwtDecode(data.token)
    localStorage.setItem(TOKEN_KEY, data.token)
    setUser({
      role: decodedToken.role,
      name: decodedToken.name,
      surname: decodedToken.surname,
      email: decodedToken.email,
      username: decodedToken.username,
      company_id: decodedToken.company_id,
    })
    setisAuthenticated(true)
  }

  const logout = () => {
    localStorage.removeItem(RABBIT_KEY)
    localStorage.removeItem(TOKEN_KEY)
    setUser(null)
    setisAuthenticated(false)
  }

  const isAuthorized = () => {
    return isAuthenticated
  }

  useEffect(
    () => {
      const handleLogoutEvent = () => {
        logout() // Assuming this function clears the auth state and local storage
        window.location.href = '/login' // Redirect to login
      }

      window.addEventListener('logoutEvent', handleLogoutEvent)

      return () => {
        window.removeEventListener('logoutEvent', handleLogoutEvent)
      }
    },
    [
      /* dependencies, if any */
    ],
  )

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isAuthorized,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
