import styles from '../styles/Frame.module.css'
import SquashMenu from './pages/SquashMenu'

const Frame = ({ onClick, data, menuItems, chatbotId }) => {
  const handleSquashClick = (e) => {
    e.stopPropagation()
  }
  return (
    <div className={styles.frame} onClick={onClick}>
      {menuItems && menuItems.length > 0 && (
        <div className={styles.squashMenuContainer} onClick={handleSquashClick}>
          <SquashMenu
            menuItems={menuItems}
            chatbotId={chatbotId} /* other props */
          />
        </div>
      )}
      <div className={styles.content}>
        <img className={styles.image} alt="Mask group" src={data.avatar} />
        <div className={styles.textContainer}>
          <span className={styles.name}>{data.title}</span>
          <span className={styles.title}>{data.name}</span>
        </div>
      </div>
      <div className={styles.description}>{data.description}</div>
    </div>
  )
}

export default Frame
